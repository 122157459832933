import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {useMainAsaSession} from'./v1.06/services/AsaSessionServices'
import { store, persistor } from "../src/v1.06/redux/store"; // Adjust the import path according to your structure

import AddPaymentMethod from "./v1.06/screens/AddPaymentMethod";
import BillingScreen from "./v1.06/screens/BillingScreen";

import GoalAdded from "./v1.06/screens/GoalAdded";
import ListPayments from "./v1.06/screens/ListPayments";

import PortfolioPayment from "./v1.06/screens/PortFolioPilot/PortfolioPayment/PortfolioPayment";
import AddressScreen from "./v1.06/screens/PortFolioPilot/Address/AddressScreen";
import AddPaymentMethodPortfolio from "./v1.06/screens/PortFolioPilot/AddPaymentMethodPortfolio/AddPaymentMethodPortfolio";
import AddCreditDebitCardScreen from "./v1.06/screens/PortFolioPilot/AddCreditDebitCardScreen/AddCreditDebitCardScreen";
import Authenticate from "./v1.06/screens/Authenticate/Authenticate";
import PortfolioGoal from "./v1.06/screens/PortFolioPilot/GoalAdded/PortfolioGoal";
import HFKLanding from "./v1.06/screens/HFK/HFKLanding";
import MoneyPatrolLanding from "./v1.06/screens/MoneyPatrol/MoneyPatrolLanding";
import MoneyPatrolLanding2 from "./v1.06/screens/MoneyPatrol/MoneyPatrolLanding2";
import KidPayLanding from "./v1.06/screens/KidPay/KidPayLanding";
import SubscriptionManagement from "./v1.06/screens/SubscriptionManagement/SubscriptionManagement";
import GuacLanding from "./v1.06/screens/Guac/GuacLanding";
import GuacSubscription from "./v1.06/screens/Guac/GuacSubscription";
import GoalSubscription from "./v1.06/screens/OneGoal/GoalSubscription";
import CancelSubscription from "./v1.06/screens/SubscriptionManagement/CancelSubscriptionScreen";
import ChangePaymentMethodScreen from "./v1.06/screens/SubscriptionManagement/ChangePaymentMethodScreen";

import ReactivatePlanScreen from "./v1.06/screens/SubscriptionManagement/ReactivatePlanScreen";
import SavePaymentCards from "./v1.06/screens/Crb/SavePaymentCards";
import LandingScreen from "./v1.06/screens/Crb/LandingScreen";
import AuthenticationScreen from "./v1.06/screens/Crb/AuthenticationScreen";
import CreditRentBoost from "./v1.06/screens/Crb/CreditRentBoost";
import Confirmation from "./v1.06/screens/Crb/Confirmation";
import HomeScreen from "./v1.06/screens/Crb/HomeScreen";
import PaymentForm from "./v1.06/screens/Crb/PaymentForm";
import SessionTokenValidate from "./v1.06/screens/SessionTokenValidate";
import {AsaStateProvider} from "./v1.06/components/AsaStateProvider"
import  SubcriptionPayment from "./v1.06/screens/SubscriptionManagement/SubscriptionPayment"; 
import  BillingAddressScreen from "./v1.06/screens/SubscriptionManagement/BillingAddressScreen"; 
import  SubscriptionAddPaymentMethod from "./v1.06/screens/SubscriptionManagement/SubscriptionAddPaymentMethod"; 
import  SubscriptionAddCreditCardScreen from "./v1.06/screens/SubscriptionManagement/SubscriptionAddCreditCardScreen"; 
import {AsaSessionContext} from "./v1.06/components/AsaSessionContext"

import { env } from './v1.06/Utils/env'
const queryClient = new QueryClient();
const routescanusecahs=['/guacsubscr','/moneypatrol','/kidpay']
function App() {
  const currentUrl = new URL(window.location.href);
  const path = currentUrl.pathname;
  
  useEffect(() => {
    const clearCache = async () => {
      // Check if cache clearing has already been performed
      console.log('clear cache startin')
      const hasClearedCache = localStorage.getItem("hasClearedCache");
      if (hasClearedCache) return;

      try {
        // Clear local storage and session storage
        localStorage.clear();
        sessionStorage.clear();

        // Delete all IndexedDB databases
        const deleteIndexedDB = async () => {
          const databases = await indexedDB.databases();
          await Promise.all(
            databases.map((db) => {
              if (db.name) {
                return indexedDB.deleteDatabase(db.name);
              }
              return Promise.resolve();
            })
          );
        };

        // Clear Cache API
        const clearCache = async () => {
          const cacheNames = await caches.keys();
          await Promise.all(
            cacheNames.map((cacheName) => caches.delete(cacheName))
          );
        };

        // Unregister service workers
        if ("serviceWorker" in navigator) {
          const registrations =
            await navigator.serviceWorker.getRegistrations();
          await Promise.all(
            registrations.map((registration) => registration.unregister())
          );
        }

        await Promise.all([deleteIndexedDB(), clearCache()]);

        // Set a flag to indicate cache has been cleared
        localStorage.setItem("hasClearedCache", "true");
      } catch (error) {
        console.error("Error clearing cache:", error);
      }
    };
   
    //if(!routescanusecahs.includes(window.location.pathname))
    //  clearCache();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AsaStateProvider>
          <QueryClientProvider client={queryClient}>
            <AsaSessionContext>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/cancelSubscription"
                    element={<CancelSubscription />}
                  />

                <Route path="/" element={<SessionTokenValidate />} />
                <Route path="/cancelled" element={<ReactivatePlanScreen />} />
                <Route
                  path="/manageSubscriptions"
                  element={<SubscriptionManagement />}
                />
                <Route path="/changepaymethod" element={<ChangePaymentMethodScreen />} />
               
                <Route path="/onegoal" element={<GoalSubscription />} />
                <Route path="/ListPay" element={<ListPayments />} />
                <Route path="/AddMethods" element={<AddPaymentMethod />} />
                <Route path="/PaymentOneGoal" element={<SavePaymentCards />} />
                <Route path="/Address" element={<BillingScreen />} />
                <Route path="/goalAdded" element={<GoalAdded />} />

                  <Route path="/guac" element={<GuacLanding />} />
                  <Route path="/guacsubscr" element={<GuacSubscription />} />

                  
                  <Route path="/hireandfirekids" element={<HFKLanding />} />

                <Route path="/portfoliopilot" element={<PortfolioPayment />} />
                <Route path="/subscriptionpayment" element={<SubcriptionPayment />} />
             
                <Route path="/AddressPortfolio" element={<AddressScreen />} />
                <Route path="/billingaddresses" element={<BillingAddressScreen />} />
                <Route path="/addpaymethod" element={<SubscriptionAddPaymentMethod />} />
                <Route path="/addcreditcard" element={<SubscriptionAddCreditCardScreen />} />
                
                <Route
                  path="/AddMethodsPortfolio"
                  element={<AddPaymentMethodPortfolio />}
                />
                <Route
                  path="/AddCreditDebitCardScreen"
                  element={<AddCreditDebitCardScreen />}
                />

                <Route path="/authenticate" element={<Authenticate />} />
                <Route path="/portfolioGoal" element={<PortfolioGoal />} />
                {/* <Route path="/moneypatrol" element={<MoneyPatrolLanding />} />*/}
                <Route path="/moneypatrol" element={<MoneyPatrolLanding2 />} />
                <Route path="/kidpay" element={<KidPayLanding />} />
                <Route path="/crb" element={<LandingScreen />} />
                <Route path="/error" element={<SessionTokenValidate />} />
                <Route path="/auth" element={<AuthenticationScreen />} />
                <Route path="/confirmation" element={<CreditRentBoost />} />
                <Route path="/done" element={<Confirmation />} />
                <Route path="/home" element={<HomeScreen />} />
                <Route path="/payment" element={<PaymentForm />} />
              </Routes>
            </BrowserRouter>
            </AsaSessionContext>
          </QueryClientProvider>
        </AsaStateProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
