export const API_URLS = {
  SUBSCRIPTION_PURCHASE: "subscriptions/PurchaseFreePlan",
  BILLING_ADDRESS: "Consumer/PostConsumerAddress",
  PAYMENT: "payment/payment",
  UPDATE_PAYMENT_METHOD: "subscriptions/UpdatePaymentMethod",
  PAYMENT_METHOD: "Payment/PaymentMethod",
  CANCEL_SUBSCRIPTION: "Consumer/CancelSubscription",
  ACTIVE_SUBSCRIPTIONS: "Consumer/ActiveSubscriptions",
  FINTECH_SUBSCRIPTIONS: "Consumer/FintechSubscriptions",
  SUBSCRIPTION_PLANS: "Fintech/SubscriptionPlans",
  RATE_ON_UPGRADE_PLAN: "Subscriptions/RateOnUpgradePlan",
  FEEDBACK: "Feedback",
  FINTECH_ALL: "Fintech/All",
  FINTECH_APP_LAYOUT: "Fintech/FintechAppLayout",
};

export const QUERY_KEYS = {
  ACTIVE_SUBSCRIPTIONS: "ActiveSubscriptions",
  FINTECH_SUBSCRIPTIONS: "FintechSubscriptions",
  BILLING_ADDRESSES: "BillingAddresses",
  PAYMENT_METHODS: "PaymentMethods",
  SUBSCRIPTION_PLANS: "SubscriptionPlans",
  RATE_ON_UPGRADE_PLAN: "RateOnUpgradePlan",
  FEEDBACKS: "Feedbacks",
  FINTECH: "Fintech",
  FINTECH_APP_LAYOUT: "FintechAppLayout",
};