import React, { useEffect, useState } from "react";



import "./SubscriptionManagement.css"; 

import visa from "../../assets/Images/visa.png";
import city_double_cash from "../../assets/Images/city_double_cash.png";
import check_everyday from "../../assets/Images/check_everyday.png";

import pwa_image from "../../assets/Images/pwa_image.png";
import { useNavigate } from "react-router-dom";
import { useFintechLayouts,useFintechSubscriptions,useActiveSubscriptions} from "../../services/AsaApiServices"
import SubscriptionManagementLogo from "./SubscriptionManagementLogo"

import SubscriptionManagementBase from "./SubscriptionManagementBase"
const SubscriptionManagement = () => {
  const navigate = useNavigate();
  const [isEditSubscription, setIsEditSubscription] = useState(false);

  const fintechLaoyut=useFintechLayouts() //getLayoutFromFintech(fintech);
  const {data:subscriptions}=useActiveSubscriptions()
  const activeSubscription=Array.isArray(subscriptions)?subscriptions?.[0]:undefined

  console.log('activeSubscription',activeSubscription)
  

 useEffect(() => {
 if(activeSubscription && activeSubscription.subscriptionStatus=="Canceled"){
  navigate("/cancelled");
 }

 }, [activeSubscription]);



  const formatDate = (dateString: string | number | Date) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric", // Correctly typed as 'numeric'
      month: "long", // Correctly typed as 'long'
      day: "numeric", // Correctly typed as 'numeric'
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };


  const handleManageTrialClick = () => {
    setIsEditSubscription(true);
  };

  const handleManagePayment = () => {

    navigate('/subscriptionpayment')
  };

  const handlecancel = () => {
    setIsEditSubscription(false);
    // navigate("/cancelSubscription");
  };
  const handleCancelSubscription = () => {
    setIsEditSubscription(false);
    navigate("/cancelSubscription");
  };

  const getImageSource = (type: string | undefined) => {
    switch (type) {
      case "ACH":
        return check_everyday;
      case "Bank":
        return check_everyday; // Assuming Bank uses the same image as check_everyday
      case "Visa":
        return visa;
      case "CC":
        return city_double_cash;
      default:
        return pwa_image;
    }
  };


  return (
    <SubscriptionManagementBase>
      <div className="flex flex-col justify-between">
     
     <div className="flex-grow">
       <div className=" rounded-3xl shadow-lg p-6 w-[22rem] text-center">
         {/* Icon Section */}
         <div className="flex items-center justify-center">
           <div className="relative p-4 rounded-full">
           <SubscriptionManagementLogo layout={fintechLaoyut}/>

           </div>
         </div>

         {/* Free Trial Info */}
         {activeSubscription?.inTrial &&
           <p className="text-[#46B601] font-medium font-poppins">
             <span className="mr-2">•</span>Free Trial in Progress
           </p>
         }
         <h1 className="text-xl text-white font-semibold">
           {activeSubscription?.subscriptionTitle}
         </h1>

         {/* Trial end date */}
         <div className="bg-[#2C2C2C] rounded-lg  p-2 relative mt-4">
           <div className="absolute top-2 left-2">
             {/* Replace the following SVG with your own */}
            
             <svg
               xmlns="http://www.w3.org/2000/svg"
               className="h-5 w-5 text-white"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
             >
               <path
                 strokeLinecap="round"
                 strokeLinejoin="round"
                 strokeWidth="2"
                 d="M12 9v2m0 4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"
               />
             </svg>
           </div>
           {activeSubscription?.inTrial  &&
               <div className="flex flex-col  items-center">
                 <div className="flex flex-col">
                   <p className="trial-end-text">
                     Your free trial will end on
                   </p>
                   <p className="trial-date-text">
                     {activeSubscription?.subscriptionEndDate
                       ? formatDate(activeSubscription?.subscriptionEndDate)
                       : "N/A"}
                   </p>

                   <p className="text-white text-center font-poppins text-xs font-semibold leading-6 tracking-tight mt-1 whitespace-nowrap">
                     <span className="opacity-50">
                       After that, you will be automatically billed{" "}
                     </span>
                     <span className="opacity-50">
                       ${activeSubscription?.recurringAmount}
                     </span>
                   </p>
                 </div>

               </div>
             }
            {!activeSubscription?.inTrial &&
             <>
               <p className="trial-end-text font-poppins">
                 Billed Monthly
               </p>
               <p className="custom-text-anytime-cancel">
                 <span className="opacity-50 font-poppins">
                   Cancel anytime
                 </span>
               </p>
             </>
            }
         </div>

         {/* Payment Info */}
         <div className="text-left mt-6 text-white">
           {!isEditSubscription && (
             <>
               <div className="flex justify-between mb-2">
                 <span className="plan-text">Plan</span>
                 <span className="guac-gold-text">
                   {activeSubscription?.subscriptionTitle}
                 </span>
               </div>
               <div className="flex justify-between mb-2">
                 <span className="plan-text">Next Payment</span>
                 <span className="guac-gold-text">
                   {activeSubscription?.subscriptionEndDate
                     ? formatDate(
                         activeSubscription?.subscriptionEndDate
                       )
                     : "N/A"}
                 </span>
               </div>
               <div className="flex justify-between mb-2">
                 <span className="plan-text">Payment Method</span>
                 <span className="flex items-center space-x-2">
                   <span className="guac-gold-text mr-2">
                     •••• {activeSubscription?.cardNumberLast4??activeSubscription?.bankAccountNumberLast4}
                   </span>
                   <span className="bg-white text-white p-1 rounded-md text-xs">
                     <img
                       src={getImageSource(
                         activeSubscription?.paymentType
                       )}
                       alt={
                         activeSubscription?.paymentType ||
                         "Payment Method"
                       }
                       className="w-8 h-5 rounded m"
                       style={{ objectFit: "contain" }}
                     />
                   </span>
                 </span>
               </div>
               <div className="flex justify-between">
                 <span className="plan-text">Total</span>
                 <span className="guac-gold-text">
                   {activeSubscription?.recurringAmount}
                 </span>
               </div>
             </>
           )}
           {isEditSubscription && (
             <>
               <div className="flex justify-between">
                 <span className="plan-text">Plan</span>
                 <span className="guac-gold-text">
                   {activeSubscription?.subscriptionTitle}
                 </span>
               </div>
               <div className="w-full text-right mb-3"
                onClick={ handleCancelSubscription}
               >
                 <p
                   className="cancel-text"
                   style= {fintechLaoyut?.buttonStyle }
                 >
                   Cancel Plan
                 </p>
               </div>

               <div className="flex justify-between mt-2">
                 <span className="plan-text">Payment Method</span>
                 <span className="flex items-center space-x-2">
                   <span className="guac-gold-text mr-2">
                     •••• {activeSubscription?.cardNumberLast4}
                   </span>
                   <span className="bg-blue-500 text-white p-1 rounded-md text-xs"
                    style= {fintechLaoyut?.buttonStyle }
                   >
                     <img
                       src={getImageSource(
                         activeSubscription?.paymentType
                       )}
                       alt={
                         activeSubscription?.paymentType ||
                         "Payment Method"
                       }
                       className="w-8 h-5 rounded m"
                       style={{ objectFit: "contain" }}
                     />
                   </span>
                 </span>
               </div>
               <div
                 className="w-full text-right  "
                 onClick={handleManagePayment}
               >
                 <p
                   className="cancel-text"
                   style= {fintechLaoyut?.buttonStyle }
                 >
                   Change Payment Method
                 </p>
               </div>
             </>
           )}
         </div>

        
         <div className="mt-6">
           {!isEditSubscription ? (
             <div className="mt-6 flex space-x-2">
               <button
                 className="cancel-trial-btn-txt"
                 onClick={handleCancelSubscription}
               >
                 {"Cancel"+(activeSubscription?.inTrial?" Trial":"")}
               </button>

               <button
                 onClick={handleManageTrialClick}
                 className="manage-trial-btn"
                 style= {fintechLaoyut?.buttonStyle }
               >
                { "Manage" +(activeSubscription?.inTrial?" Trial":"")}
               </button>
             </div>
           ) : (
             <button
               onClick={handlecancel}
               className="cancel-trial-btn w-full font-poppins"
             >
               CANCEL
             </button>
           )}
         </div>
       </div>
      
     </div>
     </div>
    </SubscriptionManagementBase>
  
  );
};

export default SubscriptionManagement;
