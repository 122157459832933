import  {  useState } from "react";
import arrowBack from "../../assets/Images/arrow_back_ios_new.png";
import money_patrol_ui from "../../assets/Images/money_patrol_logo.svg";
import circle_right_arrow from "../../assets/Images/circle_right_arrow.svg";
import back_arrow_money from "../../assets/Images/back_arroe_money.svg";


import { useIsFetching,useIsMutating } from "react-query";
import { useNavigate } from "react-router-dom";




import LoaderComponent from "../../components/LoaderComponent";
import "./MoneyPatrol.css"; // Import your CSS file for animations
import {useAsaSession,useAsaSessionPagesMutation,useIsLoading} from '../../services/AsaSessionServices'
import {useActiveSubscriptions,useSubscriptionsPlans,
  useSubscriptionPurchaseMutation} from '../../services/AsaApiServices'
import { IAsaSession, SessionPage } from "../../types/types";
import {showCustomToast} from '../../services/toastService'
const MoneyPatrolLanding2 = () => {
 
  //to remove , because it's used only for backward comp
  const [isLocalFetch,setIsLocalFetch]=useState(false)
  const {data:session}=useAsaSession()
  const {data:subscriptions}=useActiveSubscriptions()
  const {data:subscriptionsPlans}=useSubscriptionsPlans()
  const sessionMutation= useAsaSessionPagesMutation()

  const subscriptionPurchaseMutation= useSubscriptionPurchaseMutation()


  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupMessage, setPopupMessage] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState<{
    subscriptionTitle: string;
    description: string;
    oneTimeAmount: any;
    plan: any;
    recurringAmount: any;
    subscriptionIcon: string;
    billingCycle: string;
  } | null>(null);
 const [isGlobalLoading]=useIsLoading()

  // Handling Update Session Error
  const isLoading=isGlobalLoading || isLocalFetch
  const onBackButtonClick = () => {
    if (selectedPlan) {
      setSelectedPlan(null);
    } else {
      if (session) {
        const targetPage = session?.sessionPages?.find(page => page.pageNumber === 0);
        if (targetPage) {
          const pageData = JSON.parse(targetPage.pageData);
          const backActionDynamicLink = pageData.backActionDynamicLink;
          if (backActionDynamicLink) {
            window.location.href = backActionDynamicLink;
          } else {
            console.error("Back action dynamic link not found.");
          }
        }
    }
  };
}

  const onBackPlanSelect = () => {
    setSelectedPlan(null);
  };

  const getPlanCost = (plan: string, oneTimeAmount: any) => {
    switch (plan) {
      case "FREE":
        return ` ${formatAmount(oneTimeAmount)}`; // Incorporate oneTimeAmount
      case "ANNUAL":
        return `${formatAmount(oneTimeAmount)} Annual`; // Add "Annual" text
      case "LIFETIME":
        return `${formatAmount(oneTimeAmount)} One-Time`; // Incorporate oneTimeAmount
      default:
        return "";
    }
  };

  const getRecurringAmount = (plan: string, amount: any) => {
    switch (plan) {
      case "LIFETIME":
        return `+${formatAmount(amount)} Annually`;
      default:
        return "";
    }
  };

  // Helper function to format the oneTimeAmount
  const formatAmount = (amount: any) => {
    if (amount != null) {
      return `$${amount}`; // Format the amount with a dollar sign
    }
    return "";
  };

  const isEligibleForTrialPlan = subscriptionsPlans?.some(
    (plan) => plan.isConsumerEligibleForTrial
  );
  const getPlanDetailsText=()=>{
    switch (selectedPlan?.subscriptionTitle) {
        case 'FREE':
          return 'Access all Money Management features and link up to 2 accounts.'
        case 'ANNUAL':
          return 'Access all Money Management features and link all accounts.'
        default:
          return 'Access all Money Management features and link all accounts.'

    }
  }
  const getSubscribeButtonText=()=>{

    if(selectedPlan?.plan?.subscriptionTitle === "Lifetime Access")
      return "PURCHASE LIFETIME"
    if (subscriptions?.some((subscription) =>subscription.subscriptionPlanCode ===selectedPlan?.plan?.subscriptionPlanCode)){
      return selectedPlan?.plan?.isConsumerEligibleForTrial ? "START FREE TRIAL": "SUBSCRIBE"
    }
    else{
      return selectedPlan?.plan?.subscriptionTitle === "FREE" ? "FOREVER FREE" : "START FREE TRIAL"
    }

  }
  const handlePlanClick = async (plan: any) => {
    setSelectedPlan(plan); // Set the selected plan

    if(subscriptions?.some((x)=>x.subscriptionPlanCode===plan.plan?.subscriptionPlanCode)){
      navigate("/manageSubscriptions");
    }
  };
  const saveForeverSubscription = async () => {
    
    try {
      const requestData = {
        comment: "Free Plan",
        billingEmail: null,
        isAcceptTerms: true,
        firstName: null,
        middleName: null,
        lastName: null,
        subscriptionPlanCode: selectedPlan?.plan?.subscriptionPlanCode,
        address: null,
        address2: null,
        city: null,
        state: null,
        country: null,
        postalCode: null,
        couponCode: null,
      };
      await subscriptionPurchaseMutation.mutateAsync(requestData)

    } catch (error) {
      console.error(error);
      showCustomToast("Error select subscriptions",true)
    }
  }
  const handleBilling = async () => {
    // Check for active subscription plan codes
    const isSubscriptionMatched=subscriptions?.some((x)=>x.subscriptionPlanCode===selectedPlan?.plan?.subscriptionPlanCode)


    if (selectedPlan?.plan?.isFree) {
      saveForeverSubscription();

      if (session) {
        const sessionPages = session.sessionPages;
        let successDynamicLink = null;
        const targetPage = session?.sessionPages?.find(page => page.pageNumber === 0);
        if (targetPage) {
          const pageData = JSON.parse(targetPage.pageData);
          successDynamicLink = pageData.successDynamicLink;
        }


        if (successDynamicLink) {
          window.location.href = successDynamicLink;
        } else {
          console.error("Back action dynamic link not found.");
        }
      } else {
        console.error("UpdatedLocalData is not available.");
      }
    } else {
      try {
        setIsLocalFetch(true)
        const sessionToUpdate:IAsaSession ={...session} as IAsaSession
        sessionToUpdate.sessionPages=undefined
        const sessionpages=[] as SessionPage[]
        const selectPackagepage:SessionPage={
           pageNumber:2,
           pageName: "GOALS PLANS",
           pageTitle: "Select Package",
           pageDescription: "Select Package",
           pageUrl: "/moneypatrol",
           pageStatusMessage: "INCOMPLETE",
           pageData:JSON.stringify(selectedPlan?.plan)
        } as SessionPage
        sessionpages.push(selectPackagepage)
        
        sessionpages.push({
          pageNumber:81,
          pageName:'Landingyes',
          pageTitle:'Landingyes',
          pageDescription:'Landingyes',
          pageUrl:'/moneypatrol',
          pageData: JSON.stringify({Landing: "true" })
        } as SessionPage
        )

        const response= await sessionMutation.mutateAsync({asaSession:sessionToUpdate,extraPages:sessionpages})
        //for bakward compatibility
       // dispatch(setSessionData({data:response}))
       // dispatch(setSessionIDStore(state.sessionID));
       // dispatch(setAsaConsumerCode(state.asaConsumerCode));
        //dispatch(setCOnsumerAdress({data:billingAddresses}));
        //Config.token=state.token || Config.token
        //Config.X_ASA_version=state.xAsaVersion || Config.X_ASA_version
        //console.log(response)
        if(isSubscriptionMatched){
          navigate("/manageSubscriptions");

        }else{
          //navigate('/portfoliopilot')
          navigate('/subscriptionpayment')
          
        }
      } catch (error) {
       

        console.error("Error fetching session:", error);
        showCustomToast("Error processing checkouts",true)
      }
      finally{
        setIsLocalFetch(false)
      }
    }
  };

  return (
    <div className="bg-[#121212] flex justify-center items-center min-h-screen">
      {isLoading && <LoaderComponent />}
      <div className="bg-gradient-to-r from-[#05ACEC] to-[#0051C4] w-full h-screen overflow-y-auto text-white relative z-20">
        <div className="flex items-center justify-evenly space-x-8 pt-8 p-3">
          <div className="back-button-container-money">
            <img
              className="back-button"
              src={arrowBack}
              onClick={onBackButtonClick}
              alt="Back"
            />
          </div>

          <h1 className="font-poppins ml-3 text-[22px] leading-[28px] tracking-[1px] w-[500px] h-[56px] mt-6">
            Choose a plan that
            <br /> works for you.
          </h1>
        </div>

        <img
          src={money_patrol_ui}
          className="relative top-2 left-5 z-50 w-[65px] h-[65px]"
          alt="Flash"
        />

        

        <div className="bg-[#121212] top-8 rounded-more text-gray-800 mx-auto rounded-t-[3rem] absolute bottom-0 left-0 right-0 h-full overflow-y-auto mt-24">
          <div className="h-full p-6 shadow-lg">
            <span className="font-semibold relative -top-4 font-poppins text-[18px] ml-20 text-white">
              Money Patrol
            </span>
 {/* start refactor. */}
          {selectedPlan &&
                          <div className="bg-[#0B1727] mt-10 text-white w-full \ rounded-lg shadow-lg border-2 border-[#2F3947] p-6 flex flex-col items-center">
                          <div className="w-full flex justify-between items-center mb-4">
                            <div className="flex items-center">
                              <img
                                src={back_arrow_money}
                                alt="Back"
                                className="cursor-pointer w-[22px] h-[22px] mr-2"
                                onClick={onBackPlanSelect}
                              />
                              <span className="text-[18px] font-poppins">
                                {selectedPlan.subscriptionTitle}
                              </span>
                            </div>
                            <div className="subscription-title-money">
                              {getPlanCost(
                                selectedPlan.subscriptionTitle,
                                selectedPlan?.oneTimeAmount
                              )}
                            </div>
                          </div>

                          <div className="w-full flex justify-between items-center mb-4">
                            <div className="flex items-center">
                              {""}
                            </div>
                            <div className="subscription-title-add-money">
                              {getRecurringAmount(
                                selectedPlan.subscriptionTitle,
                                selectedPlan?.recurringAmount
                              )}
                            </div>
                          </div>
                          
                          <p className="text-[14px] mb-2 leading-[1.4]">
                            {selectedPlan.description}
                          </p>
          
                          <p className="text-[14px] mb-4 mt-4">
                            <span className="mr-2">•</span>
                            {getPlanDetailsText()}
                          </p>
                        </div>
            }
            {!!!selectedPlan &&
              <div className="bg-[#121212] text-white h-screen flex flex-col">
              <div className="flex flex-col mt- items-center justify-center ">
                <p className="px-6 mb-8 mt-8 font-poppins">
                  Our users have reported an average of $5K+ positive impact
                  on their personal finances .
                </p>

                {/* Find the FREE plan in the plans array */}
                { subscriptionsPlans &&
                  subscriptionsPlans.length > 0 && isEligibleForTrialPlan
                  &&
                  <button className="bg-[#22262c] text-white px-20 py-3 rounded-lg mb-6 font-poppins">
                      15-Day Free Trial
                  </button>
                }
              </div>

              <div className="flex flex-col items-center space-y-6 mb-8">
                {subscriptionsPlans && subscriptionsPlans
                  .filter((plan) => plan.subscriptionTitle.trim() !== "") // Filter out empty titles
                  .map((plan) => {
                    // Remove everything after "Annual" or "Lifetime" (including spaces)
                    const displayTitle = plan.subscriptionTitle
                      .replace(/(Annual|Lifetime).*$/, "$1")
                      .toUpperCase();

                    return (
                      <button
                        key={plan.subscriptionPlanId}
                        className="bg-[#0B1727] px-6 py-4 h-[60px] w-full rounded-[15px] flex justify-between items-center border-[2px] border-custom-gray"
                        onClick={() =>
                          handlePlanClick({
                            subscriptionTitle: displayTitle,
                            description: plan.description,
                            oneTimeAmount: plan.oneTimeAmount,
                            plan: plan,
                            recurringAmount: plan.recurringAmount,
                            subscriptionIcon: plan.subscriptionIcon,
                            billingCycle: plan.billingCycle
                          })
                        }
                      >
                        {displayTitle}
                        <img
                          src={circle_right_arrow}
                          alt="Arrow"
                          className="w-6 h-6"
                        />
                      </button>
                    );
                  })}
              </div>
            </div>              
            }

            {selectedPlan && subscriptions && 
              <button
                className="bg-[#006BD2] text-white px-4 py-2 rounded-lg mt-4 fixed bottom-4 left-1/2 transform -translate-x-1/2 max-w-xs w-full font-semibold"
                onClick={() => {
                  handleBilling();
                }}
              >
                {getSubscribeButtonText()}
              </button>
            }
 {/* end refactor. */}            

          </div>

          {/* Enhanced Popup Modal */}
          {showPopup && (
            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ease-in-out">
              <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm mx-4 sm:mx-8 lg:mx-12">
                <div className="flex flex-col items-center">
                  <div className="text-center mb-4">
                    <p className="font-poppins text-xl text-gray-800">
                      {popupMessage}
                    </p>
                  </div>
                  <button
                    className="bg-[#006BD2] text-white px-4 py-2 rounded-md font-semibold hover:bg-[#169b6b] transition-colors duration-200"
                    onClick={() => setShowPopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MoneyPatrolLanding2;
