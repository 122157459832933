import React, { useState, useEffect, useCallback, useRef,useContext } from "react";
import gold from "../../assets/Images/demo/gold.png";
import mobile from "../../assets/Images/demo/mobile.png";
import slider1 from "../../assets/Images/demo/slider_2_guac.png";
import slider2 from "../../assets/Images/demo/guac_slider_3.svg";
import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import guac from "../../assets/Images/demo/guac_relative_icon.svg";
import green from "../../assets/Images/demo/guac_green.svg";
import "../../assets/css/style.css";
import { useNavigate } from "react-router-dom";
import { Config } from "../../Utils/AppConstants";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import "./GuacLanding.css"; // Import your CSS file for animations
import {useAsaSession,useIsLoading,useAsaSessionPagesMutation,useBackButton} from '../../services/AsaSessionServices'

import {  useActiveSubscriptions,useSubscriptionsPlans} from '../../services/AsaApiServices'
import { IAsaSession, Plan, SessionPage } from "../../types/types";

import LoaderComponent from "../../components/LoaderComponent";
interface Slide {
  icon: string;
  title: string;
  description: string;
}

interface CustomSliderProps {
  slides: Slide[];
}

interface CustomSliderProps {
  slides: Slide[];
  subscriptionAmount: any; // Add activePlanCodes prop
  onSlideClick:(idx:number)=>void
  isSubscribed:boolean
  isExistSubscriptionPlans:boolean
}

const CustomSlider: React.FC<CustomSliderProps> = ({
  slides,
  subscriptionAmount,
  onSlideClick,
  isSubscribed,
  isExistSubscriptionPlans, 
}) => {

  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const startXRef = useRef<number>(0);
  const dragOffsetRef = useRef<number>(0);
  const [dragOffset, setDragOffset] = useState<number>(0);
  const [isContinueMode,setIsContinueMode] =useState(false);


  const handleStart = (clientX: number) => {
    startXRef.current = clientX;
    setIsDragging(true);
  };

  const handleMove = (clientX: number) => {
    if (!isDragging) return;
    const diff = startXRef.current - clientX;
    dragOffsetRef.current = diff;
    setDragOffset(diff);
  };

  const handleEnd = () => {
    if (!isDragging) return;
    const threshold = (sliderRef.current?.offsetWidth ?? 0) / 3;

    if (Math.abs(dragOffsetRef.current) > threshold) {
      if (dragOffsetRef.current > 0 && currentSlide < slides.length - 1) {
        setCurrentSlide((prev) => prev + 1);
      } else if (dragOffsetRef.current < 0 && currentSlide > 0) {
        setCurrentSlide((prev) => prev - 1);
      }
    }

    setIsDragging(false);
    setDragOffset(0);
  };

  const handleDotClick = (index: number) => {
    setCurrentSlide(index);
  };





  useEffect(() => {
    const slider = sliderRef.current;

    if (!slider) return;

    const touchStart = (e: TouchEvent) => handleStart(e.touches[0].clientX);
    const touchMove = (e: TouchEvent) => handleMove(e.touches[0].clientX);
    const touchEnd = () => handleEnd();

    const mouseDown = (e: MouseEvent) => handleStart(e.clientX);
    const mouseMove = (e: MouseEvent) => handleMove(e.clientX);
    const mouseUp = () => handleEnd();

    slider.addEventListener("touchstart", touchStart, { passive: true });
    slider.addEventListener("touchmove", touchMove, { passive: true });
    slider.addEventListener("touchend", touchEnd);
    slider.addEventListener("mousedown", mouseDown);
    window.addEventListener("mousemove", mouseMove);
    window.addEventListener("mouseup", mouseUp);

    return () => {
      slider.removeEventListener("touchstart", touchStart);
      slider.removeEventListener("touchmove", touchMove);
      slider.removeEventListener("touchend", touchEnd);
      slider.removeEventListener("mousedown", mouseDown);
      window.removeEventListener("mousemove", mouseMove);
      window.removeEventListener("mouseup", mouseUp);
    };
  }, [isDragging, slides.length]);

//console.log(Config)
  return (
    <div
      ref={sliderRef}
      className="relative w-full slider-guac bg-[#EBECF0] border-[3.5px] border-yellow-500 rounded-2xl overflow-hidden flex flex-col h-auto mt-8"
    >
      <div className="absolute top-2 left-2 z-10 --guac-img-gold">
        <img src={gold} className="object-contain w-16" alt="Gold" />
      </div>

      <div className="w-full box-guac bg-[#FBEFCF] flex-shrink-0">
        <div
          className="flex transition-transform duration-300 ease-out h-full"
          style={{
            transform: `translateX(calc(-${currentSlide * 100}% + ${
              isDragging ? -dragOffset : 0
            }px))`,
            transition: isDragging ? "none" : "transform 300ms ease-out",
          }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="w-full flex-shrink-0 p-4 flex justify-center items-center __asa_guac_slider"
            >
              <div className="--side-image">
                <img
                  src={slide.icon}
                  alt={slide.title}
                  className="max-w-full h-full object-contain"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col overflow-y-hidden h-auto sm:h-60 bg-yellow-50 justify-between p-4">
        <div className="overflow-y-auto">
          <h3 className=" mb-2 text-black guac-slider-title font-poppins">
            {slides[currentSlide].title}
          </h3>
          <p className="guac-slider-description font-poppins">
            {slides[currentSlide].description}
          </p>
        </div>

        <div className="flex justify-center space-x-2 my-4">
          {slides.map((_, index) => (
            <div
              key={index}
              onClick={() => handleDotClick(index)}
              className={`w-2 h-2 rounded-full cursor-pointer ${
                index === currentSlide ? "bg-[#EBAE11]" : "bg-gray-300"
              }`}
            />
          ))}
        </div>

        {!isContinueMode &&
          <button
            onClick={()=>setIsContinueMode(true)}
            className="w-full bg-[#EBAE11] slider-guac text-white py-3 rounded-lg shadow-md font-poppins font-semibold"
          >
            Continue
          </button>
         } 
        
        {isContinueMode && isExistSubscriptionPlans &&
          <button
            onClick={()=>onSlideClick(currentSlide)}
            className="w-full bg-[#EBAE11] slider-guac text-white py-3 rounded-lg shadow-md font-poppins font-semibold"
          >
            {isSubscribed ? "Manage Plan" : `${subscriptionAmount}/MONTH`}
          </button>
        } 
         {isContinueMode && !isExistSubscriptionPlans &&
            <div className="mb-2 text-black  font-poppins">
                No subscriptions plans available
            </div>
         }
      </div>
    </div>
  );
};

export default function GuacLanding() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useIsLoading();
  
  const {data:session}=useAsaSession()
  const {data:subscriptions}=useActiveSubscriptions()
  const {data:subscriptionsPlans}=useSubscriptionsPlans()
  const sessionMutation= useAsaSessionPagesMutation()

  const handleBackClick=useBackButton()

  const updateSessiongoal = async (
    plan: Plan | undefined,
  ) => {


      const sessionToUpdate:IAsaSession ={...session} as IAsaSession
      sessionToUpdate.sessionPages=undefined
      const sessionpages=[] as SessionPage[]
      sessionpages.push({
        pageNumber:2,
        pageName: "GOALS PLANS",
        pageTitle: "Select Package",
        pageDescription: "Select Package",
        pageUrl: "/guac",
        pageStatusMessage: "INCOMPLETE",
        pageData:JSON.stringify(plan)
     } as SessionPage)
      sessionpages.push({
        pageNumber:81,
        pageName:'Landingyes',
        pageTitle:'Landingyes',
        pageDescription:'Landingyes',
        pageUrl:'/guac',
        pageData: JSON.stringify({Landing: "true" })
      } as SessionPage
      )

      const response= await sessionMutation.mutateAsync({asaSession:sessionToUpdate,extraPages:sessionpages})
    try {

      const response= await sessionMutation.mutateAsync({asaSession:sessionToUpdate,extraPages:sessionpages})
      
      return response;
    } catch (error) {
     
      
      console.error("Error updating session:", error);
      
    }
  };
  // Handling Update Session response

  const handleSlideClick = async (idx: number) => {
    if (subscriptions?.some(()=>true)) {
      navigate("/manageSubscriptions");
    }else{
      await updateSessiongoal(subscriptionsPlans?.[0]);
      navigate("/subscriptionpayment");
    }

  }
 
  const slides: Slide[] = [
    {
      icon: mobile,
      title: "Move your money faster!",
      description:
        "Withdraw funds from your Goals to Bank the same day for Free! Guac Gold includes 2 Free Rapid Withdrawals with absolutely no fees (normally 1.5%).",
    },
    {
      icon: slider1,
      title: "It's hard to limit yourself to two savings goals!",
      description:
        "Upgrade to Guac Gold & Save for Multiple Goals at the same time.",
    },
    {
      icon: slider2,
      title: "Monitor and Build Credit!",
      description:
        "Stay on Track with access to real-time Credit Scores and Improve your Credit with Exclusive Access to Pre-Qualified Personal Loans and Offers!",
    },
  ];

  return (
    <div className="flex justify-center items-center overflo h-screen">
      {isLoading && <LoaderComponent />}
      <div className="w-full h-full gradient-bg-guac-gr flex flex-col bg-gradient-guac text-white relative z-20">
        <div className="flex items-center justify-between p-4 mt-2">
          <span className="back-arrow">
            <img
              alt="back"
              onClick={handleBackClick}
              className="cursor-pointer p-2 shadow-2xl rounded-lg w-7 h-7"
              src={arrow_back_ios_new}
            />
          </span>
          <h1 className="header-title">Achieve more with Guac Gold!</h1>
        </div>

        <div
          className={`flex-grow relative rounded-t-3xl mt-6 p-6 flex flex-col ${
            Config.isLight ? "bg-[#EBECF0]" : "bg-[#121212]"
          }`}
        >
          {" "}
          <div className="relative mb-2">
            <img
              alt="Guac"
              src={guac}
              className="absolute -top-10 opacity-90 left-0 z-50 w-20 h-20"
            />

            <span className="custom-span absolute left-24 -top-4 text-[#FFFFFF]">
              Guac
            </span>
          </div>
          <div className="flex flex-col justify-between">
            <div className="flex-grow">
              <CustomSlider
                slides={slides}
                subscriptionAmount={subscriptionsPlans?.[0]?.oneTimeAmount}
                onSlideClick={ handleSlideClick}
                isSubscribed={subscriptions?.some(()=>true)??false}
                isExistSubscriptionPlans={subscriptionsPlans?.some(()=>true)??false}
              />
              {/* <CustomSlider slides={slides} /> */}
            </div>

            <div className="text-left mt-6 bg-white space-y-4 p-2 rounded-xl border border-gray-300 shadow-lg custom-container">
              {" "}
              <img
                className="object-contain w-24 mr-1"
                src={green}
                alt="Green"
              />
              <p className="custom-paragraph font-poppins">
                You will be moved to Guac Green if you don't upgrade within 30
                days.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
