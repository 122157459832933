import React, { useState,createContext,ReactNode, SetStateAction } from 'react';
import {IAsaSessionState} from '../types/types'
import {useMainAsaSession} from '../services/AsaSessionServices'
const AsaLoadingContext = createContext<[boolean,React.Dispatch<SetStateAction<boolean>>]>([false,(s)=>{}]);
interface Props {
    children?: ReactNode
    // any props that come into the component
  }
const AsaSessionContext = ({ children, ...props }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const session=useMainAsaSession(setIsLoading)
   
    return (
      <AsaLoadingContext.Provider value={[isLoading,setIsLoading]}>
        {children}
      </AsaLoadingContext.Provider>
    );
  };
  export {AsaLoadingContext, AsaSessionContext}