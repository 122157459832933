import React, { useEffect, useState, useMemo } from "react";
import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import "./SubscriptionPayment.css"; // Plan NameImport your CSS file for animations
import { useNavigate } from "react-router-dom";
import showSubscriptionToast from "./SubscriptionToast"
import {useAsaSession,useAsaSessionPagesMutation,useIsLoading,useBackButton} from '../../services/AsaSessionServices'
import {useActiveSubscriptions,useSubscriptionsPlans,
  useRateOnUpgradePlan,useFintechLayouts,useBillingAddresses,
  useSubscriptionPaymentMutation,usePaymentMethods,
  useSubscriptionPaymentMethodUpdateMutation,
  } from '../../services/AsaApiServices'


import { useDispatch, useSelector } from "react-redux";
import {
  SessionState,
  setNewPaymentField,
  setPaymentCVV,
  setSelectedPaymentMethod,
  setSelectedBillingAddress,
} from "../../redux/sessionSlice";

import { BillingAddress, PaymentMethod, SubscriptionPlanData} from "../../types/types";
import ConfirmDialogCVV from "../../components/ConfirmDialogCVV";
import LoaderComponent from "../../components/LoaderComponent";
import PaymentMethodCard  from "../../components/PaymentMethodCard";
import BillingAddressCard from "../../components/BillingAddressCard";
import Dropdown from "./DropDown";
// this is first step of refactoring of Portfoliopilot page without touching logic

const SubscriptionPayment: React.FC = () => {

  const {data:billingAddresses}=useBillingAddresses()

  const {data:paymentMethods}= usePaymentMethods()
  const layout=useFintechLayouts()
  const {data:session}=useAsaSession()

  const sessionMutation=useAsaSessionPagesMutation()
  const {data:activesubscriptions}=useActiveSubscriptions()
  const subscriptionPaymentMutation= useSubscriptionPaymentMutation();
  const subscriptionPaymentMethodMutation=useSubscriptionPaymentMethodUpdateMutation()

  const {data:subscriptionsPlans}=useSubscriptionsPlans()
  const [isLoading,]=useIsLoading()
  const [paymentMethod,setPaymentMethod] = useState<PaymentMethod | undefined>()
  const [billingAddr,setBillingAddr] = useState<BillingAddress | undefined>()
  const activeSubscription=activesubscriptions?activesubscriptions[0]:undefined

  const fintechName=layout?.fintechName

  const navigate = useNavigate();
  const [cvv, setCVV] = useState("");
  const [showDialogCVV, setShowDialogCVV] = useState(false);
  const handleBackToAppClick=useBackButton()
  const dispatch = useDispatch();


  const paymentCVVs = useSelector(
    (state: SessionState) => state.session.paymentCVVs
  );
  const getSelectedBillingfromStore = useSelector(
    (state: SessionState) => state?.session.selectedBillingAddress
  );

  const getSelectedPaymentfromStore = useSelector(
    (state: SessionState) => state?.session.selectedPaymentMethod
  );

  const handleCVVChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    paymentMethodId: number
  ) => {
    const newCVV = e.target.value;
    // Ensure that CVV is not logged or displayed inappropriately
    setCVV(newCVV);
    dispatch(setNewPaymentField(newCVV)); // Only use newCVV here
    // Update CVV in Redux store
    dispatch(setPaymentCVV({ paymentMethodId, cvv: newCVV }));


  };

const backToNavigate=useMemo(()=>{
  const targetPage = session?.sessionPages?.find(page => page.pageNumber === 81);
  
  const pageUrlToNavigate = targetPage?.pageUrl;
  return pageUrlToNavigate
},[session])
const newSubscriptionPlan:SubscriptionPlanData | undefined=useMemo(()=>{
  const targetPage = session?.sessionPages?.find(page => page.pageName === 'GOALS PLANS')
                    || session?.sessionPages?.find(page => page.pageName === 'SelectedPlans')

  if(targetPage){
    const parsedPageData = JSON.parse(targetPage.pageData.replace(/\\/g, ""));
    return parsedPageData
  }
},[session]
) 
const {data:upgradeRate}= useRateOnUpgradePlan(activeSubscription?.subscriptionPlanCode,newSubscriptionPlan?.subscriptionPlanCode)
const activePlanData=subscriptionsPlans?.find(x=>x.subscriptionPlanCode===activeSubscription?.subscriptionPlanCode)
const IsSubscriptionUpgrade=activePlanData?activePlanData?.subscriptionPlanCode===newSubscriptionPlan?.subscriptionPlanCode:false

const isLanding:boolean=useMemo(()=>{
  const targetPage = session?.sessionPages?.find(page => page.pageName === 'Landingyes');
  const parsedPageData = JSON.parse(targetPage?.pageData?.replace(/\\/g, "") || '{}'); 
  return parsedPageData?.Landing
},[session]
) 
  
  useEffect(()=>{
   if(paymentMethods && paymentMethods?.length>0 && session && getSelectedPaymentfromStore ){
      const matchedStore=paymentMethods.find(x=>x.paymentMethodId===getSelectedPaymentfromStore?.paymentMethodId)
      if(matchedStore){
        setPaymentMethod(matchedStore)
        setCVV(paymentCVVs[matchedStore.paymentMethodId] || "")
      }
   }
  },[paymentMethods,session,getSelectedPaymentfromStore])

  useEffect(()=>{
    if(billingAddresses && billingAddresses?.length>0 && getSelectedBillingfromStore){
        const matchedStore=billingAddresses.find(x=>x.consumerAddressId===getSelectedBillingfromStore?.consumerAddressId)
        if(matchedStore){
          setBillingAddr(matchedStore)
        }
    }
   },[billingAddresses,getSelectedBillingfromStore])

  const handleReviewAndPayClick = () => {
    // Handle click action, such as navigating to another screen or performing some other action
    if (isLanding && !!backToNavigate) {
          navigate(backToNavigate);
    }
  };

  const onAddPaymentMethod = () => {
    navigate("/addpaymethod",{ state: { returnurl: "/subscriptionpayment" } });
  };

  const addBillingAddress = () => {
    navigate("/billingaddresses",{ state: { returnurl: "/subscriptionpayment" } });
  };

  const handlePaymentSelection =  (method: PaymentMethod) => {

    if (method.paymentType === "CC"){
      setCVV(paymentCVVs[method.paymentMethodId] || "");
    }
    setPaymentMethod(method);
    dispatch(setSelectedPaymentMethod(method));
   
  
  };



  const handleBackClick = () => {
    if (isLanding) {
      if (backToNavigate) {
        navigate(backToNavigate);
      } else {
        console.error("Page URL for page number 81 not found.");
      }
      
    } else {
      handleBackToAppClick()
    }
  };

 
  const handleAddressSelection =  (
    address: BillingAddress 
  ) => {
    
    // Toggle options if the same item is clicked again
    if (billingAddr !== address){
      setBillingAddr(address); // Set the new selected address index

    }
    dispatch(setSelectedBillingAddress(address));
  };

  
  const handleConfirmCVV = (enteredCVV: string) => {
    setCVV(enteredCVV);
    setShowDialogCVV(false);
    handlePaymentProcess(
      billingAddr,
      paymentMethod,
      enteredCVV
    );
  };


  const handlePurchase = async () => {
    handlePaymentProcess(billingAddr, paymentMethod );
  };

  const handleUpdate = async () => {
    
    await handleUpdatePaymentMethodProcess(billingAddr,paymentMethod);
  };



  const handlePaymentProcess = async (
    billingfromStore: any,
    paymentfromStore: any,
    enteredCVV?: string | undefined
  ) => {
    // Check CVV only if payment type is 'cc'
   
    // Get the CVV corresponding to the selected payment method
    const selectedPaymentMethodId = paymentfromStore?.paymentMethodId;
    const cvvToUse = enteredCVV || paymentCVVs[selectedPaymentMethodId] || cvv;
  
    
    if ( paymentfromStore?.paymentType === "CC" && !!!enteredCVV){
      setShowDialogCVV(true)
      return
    }
    if (paymentfromStore?.paymentType === "CC" &&
      (!/^\d+$/.test(cvvToUse) || !(cvvToUse.length === 3 || cvvToUse.length === 4))
    ) {
  
      showSubscriptionToast(" Error in CVV", true);
      return;
    }
    if ( paymentfromStore?.paymentType === "CC" 
      && !!paymentCVVs[selectedPaymentMethodId]
      && enteredCVV!==paymentCVVs[selectedPaymentMethodId]){
      showSubscriptionToast("CVV doesn't match initial entry, please edit payment method", true);
      return;
    }
    const billingAmount=IsSubscriptionUpgrade  && !!upgradeRate ? upgradeRate?.proratedAmount : newSubscriptionPlan?.oneTimeAmount



    const requestBody = {
      products: [""],
      billingDetailRequestModel: {
        billingAmount: billingAmount,
        comment: `Payment for Fintech ${fintechName}`,
        isRecurring: newSubscriptionPlan?.isRecurring,
        RecurringAmount:newSubscriptionPlan?.recurringAmount,
        isSubscription: true,
        consumerSignature: null,
        autoPay: true,
        recurringStartDate: null,
        billingEmail: null,
        paymentMethodId: paymentfromStore?.paymentMethodId,
        paymentMethodCode: paymentfromStore?.asaPaymentMethodCode,
        BillingCycle: newSubscriptionPlan?.billingCycle,
        isAcceptTerms: true,
        paymentType: null,
        bankAccountNumber: null,
        bankRoutingNumber: null,
        bankName: null,
        cardType: null,
        cardNumber: null,
        expiryMonth: null,
        expiryYear: null,
        firstName: null,
        middleName: null,
        lastName: null,
        CardHolderName:
          paymentfromStore?.paymentType === "ACH"
            ? null
            : paymentfromStore?.cardHolderName,
        CVV: paymentfromStore?.paymentType === "ACH" ? null : cvvToUse,
        subscriptionPlanCode: activePlanData
          ? activePlanData?.subscriptionPlanCode
          : newSubscriptionPlan?.subscriptionPlanCode,
        address: billingfromStore?.address1,
        address2: billingfromStore?.address2,
        city: billingfromStore?.city,
        state: billingfromStore?.state,
        country: billingfromStore?.country,
        postalCode: billingfromStore?.zipCode,
        fintechApplicationCode:session?.fintechApplicationCode
      },
    };
    try {
      const res = await subscriptionPaymentMutation.mutateAsync(requestBody);
      redirectToApp(res?.message, true, 200);
    } catch (error: unknown) {
      if (error instanceof Error) {
        redirectToApp(error.message, false, 500);
      } else {
        redirectToApp("An unknown error occurred", false, 500);
      }
    }
  };
  const redirectToApp=(msg:string,isSuccess:boolean,status:number)=>{
    showSubscriptionToast(msg, !isSuccess);
    const targetPage = session?.sessionPages?.find(page => page.pageNumber === 0);
    if (targetPage) {
        const pageData = JSON.parse(targetPage.pageData);
        const backActionDynamicLink = pageData.backActionDynamicLink;
        const successDynamicLink=pageData.successDynamicLink;
        const url_params = new URLSearchParams([["statusMessage",msg],["statusCode",status.toString()]])
        const new_url = new URL(`${successDynamicLink}?${url_params}`);
        console.log(new_url)
        setTimeout(() => {
          if(successDynamicLink){
             window.location.href=new_url.toString()
          }else{
            navigate("/portfolioGoal", { state: msg});
          } 
        }, 1000); // Delay for 1 second
    }else{
        navigate("/portfolioGoal", { state: msg});
    }
  }
  const handleUpdatePaymentMethodProcess = async (
    billingfromStore: any,
    paymentfromStore: any,
  
  ) => {

    // Check CVV only if payment type is 'cc'

    // Get the CVV corresponding to the selected payment method
 
    //const billingAmount=IsSubscriptionUpgrade  && !!upgradeRate ? upgradeRate?.proratedAmount : newSubscriptionPlan?.oneTimeAmount
    // Check  exists and `inTrial` is `false`


    const requestData = {
      paymentMethodCode: paymentMethod?.asaPaymentMethodCode ,
      billingDetailCode: activeSubscription?.billingDetailCode, // Assuming this value is constant  subscriptionPlanCode: activePlanData?.subscriptionPlanCode || 15,
      address: billingfromStore?.address1,
      address2: null,
      city: billingfromStore?.city ,
      state: billingfromStore?.state,
      country: billingfromStore?.country || "US",
      postalCode: billingfromStore?.zipCode,
      //billingAmount: billingAmount,
      subscriptionPlanCode:activeSubscription?.subscriptionPlanCode
      // in case of upgrade
      //subscriptionPlanCode: newSubscriptionPlanData?.pageData.subscriptionPlanCode 
    };
    try{
      const res=await subscriptionPaymentMethodMutation.mutateAsync(requestData)
      redirectToApp(res?.message,true,200)
      //navigate("/portfolioGoal", { state: res?.message});
      
    }
    catch(error){
      if (error instanceof Error) {
        redirectToApp(error.message, false, 500);
      } else {
        redirectToApp("An unknown error occurred", false, 500);
      }

    }
  };

  const getPeriodCharge = () => {
    if (newSubscriptionPlan) {
      if (newSubscriptionPlan?.oneTimeAmount !== newSubscriptionPlan?.recurringAmount) {
        return "one-time"
      }
      return newSubscriptionPlan?.billingCycle === "Annually" ? "/Year" : "/Month"
    }
    return "No subscription plan selected"
  }

  const getDisclosureText = () => {
    // TODO: - Need to add disclosure text to ASACentral Config.
    if (fintechName === "Money Patrol") {

      switch (newSubscriptionPlan?.subscriptionTitle) {
        case "Lifetime Access":
          return {
              title: `+ yearly recurring payment of $${newSubscriptionPlan?.recurringAmount}`,
              descr: "starting in one year, cancel anytime. "
          };
        case "Annual Subscription":
          return {
            title: "Cancel anytime, no payment due today.",
            descr: `monthly payments start in ${newSubscriptionPlan?.freeTrialPeriod-1} days.`
        };
        default:
          return null;
      }
    }
    return null
  }
  const isTrialStart=newSubscriptionPlan?.isConsumerEligibleForTrial && newSubscriptionPlan?.freeTrialPeriod>0

  return (
    <div className="relative gradient-bg-listpayments bg-gradient-to-b from-[#705AE2] to-[#490C95] flex flex-col justify-between overflow-y-scroll py-10 text-gray-100 mx-auto space-y-5 h-screen"
    style= {layout?.backGroundStyle }
    >
      {(isLoading) && <LoaderComponent />}
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-center space-x-4">
          <img
            className="w-6 h-6 sm:w-8 sm:h-8 ml-5"
            src={arrow_back_ios_new}
            alt="Back"
            onClick={handleBackClick} // Updated onClick handler for the back button
          />
          <p className="custom-style-bugs">Checkout with ASA Pay®</p>
        </div>
      </div>
      <div className="absolute top-24 left-0 card-lay-portfolio right-0 bottom-0 rounded-t-3xl p-4 z-10 overflow-y-auto more-rounded">
{/* Payment Method Dropdown */}
        <Dropdown
          items={paymentMethods || []}
          selectedItem={paymentMethod}
          onSelect={handlePaymentSelection}
          renderItem={(method, isSelected,isSelectable) => (
            <PaymentMethodCard
              isSelectable={isSelectable}
              method={method}
              isSelected={isSelected}
              currentCVV={paymentCVVs[method.paymentMethodId] || ""}
              onSelect={handlePaymentSelection}
              onCVVChange={handleCVVChange}
            />
          )}
          addButtonLabel="Add another payment method"
          onAddButtonClick={onAddPaymentMethod}
        />

        <div className="divider-pportfolio"></div>
       
        {paymentMethod  && paymentMethod?.paymentType === "CC" &&
          
            <>{/* Billing Address Dropdown */}
                <Dropdown
                  items={billingAddresses || []}
                  selectedItem={billingAddr}
                  onSelect={handleAddressSelection}
                  renderItem={(address, isSelected,isSelectable) => (
                    <BillingAddressCard
                      isSelectable={isSelectable}
                      address={address}
                      isSelected={isSelected}
                      onSelect={handleAddressSelection}
                    />
                  )}
                  addButtonLabel="Add a billing address"
                  onAddButtonClick={addBillingAddress}
                />
              <div className="divider-billdialog-pportfolio"></div>

            </>
         }
        {showDialogCVV && (
          <ConfirmDialogCVV
            title="Add CVV"
            message="Please Enter Cvv"
            onCancel={() => setShowDialogCVV(false)}
            onConfirm={handleConfirmCVV} // Ensure this function is called on confirmation
            isOpen={showDialogCVV}
          />
        )}
        {newSubscriptionPlan &&
          ((paymentMethod?.paymentType === "CC" &&
            paymentMethod != null &&
            billingAddresses != null) ||
            (paymentMethod?.paymentType === "ACH" &&
              paymentMethod != null)) && (
            <div className="mt-6 mb-20">
              <h2 className="text-[#FFFFFF] text-[20px] leading-6 mb-4 font-poppins font-bold ml-2">
                Review & Pay
              </h2>
              <div className="flex items-center justify-between rounded-lg">
                <div className="w-16 h-16 p-2 rounded-12">
                  <img
                    onClick={isLanding ? handleReviewAndPayClick : () => {}}
                    className="w-full h-full cursor-pointer"
                    src={
                      newSubscriptionPlan?.subscriptionIcon
                        ? newSubscriptionPlan?.subscriptionIcon
                        : ""
                    }
                    alt="Subscription Icon"
                  />
                </div>

                <div className="flex-1 text-left ml-2">
                  <p
                    className="review-name-p"
                    onClick={isLanding ? handleReviewAndPayClick : () => {}} // Use a no-op function if not clickable                    className="text-[#FFFFFF] font-semibold font-poppins text-[14px] leading-4 text-left"
                  >
                    {fintechName}
                  </p>

                  <p
                    onClick={isLanding ? handleReviewAndPayClick : () => {}} // Use a no-op function if not clickable                    className="text-[#FFFFFF] font-semibold font-poppins text-[14px] leading-4 text-left"
                    className="eview-pay-fintech text-light font-poppins text-[12px] font-medium leading-4 mt-1"
                  >
                    {newSubscriptionPlan?.subscriptionTitle}
                  </p>
                </div>
                <div className="text-right">
                  {newSubscriptionPlan && newSubscriptionPlan?.inTrial === false &&
                  activePlanData &&
                  newSubscriptionPlan ? (
                    <>
                      <p className="text-[#FFFFFF] font-poppins font-semibold text-[14px]">
                        $
                        {activePlanData?.recurringAmount &&
                        newSubscriptionPlan?.recurringAmount &&
                        activePlanData.recurringAmount >
                        newSubscriptionPlan?.recurringAmount
                          ? activePlanData.recurringAmount
                          : activePlanData?.recurringAmount || "N/A"}
                      </p>
                      <p className="text-light leading-6 text-[12px] font-poppins">
                        {newSubscriptionPlan?.billingCycle ===
                        "Annually"
                          ? "Year"
                          : "Month"}
                      </p>
                      {activePlanData?.recurringAmount >
                        newSubscriptionPlan?.recurringAmount && (
                        <p className="text-[#FFFFFF] font-poppins font-semibold text-[14px]">
                          Upgrade Cost: ${upgradeRate?.proratedAmount || "N/A"}
                        </p>
                      )}
                    </>
                  ) : (
                    <div className="text-right">
                      <p className="review-recurring-amount text-[#FFFFFF] font-poppins font-semibold text-[14px]">
                        ${newSubscriptionPlan?.oneTimeAmount}
                      </p>
                      <p className="subscription-type text-light leading-6 text-[12px] font-poppins">
                        {getPeriodCharge()}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {/* Approve Payment Button */}

              {getDisclosureText() !== null ? (

<div className="group-82805">
<div className="rounded-rectangle"></div>
  <div className="labelDisclosureTitle">{getDisclosureText()?.title}</div>
  <div className="labelDisclosureDescr">{getDisclosureText()?.descr}</div>

</div>

              ) : null
              }
              <div className="fixed bottom-0 left-0 right-0 p-4">
                <button
                  onClick={() => {
                    
                    if (!!activeSubscription) {  ///to do
                      // handleUpdate(); // Call your update method here

                      handleUpdate();
                    } else {
                      handlePurchase( );
                    }
                  }}
                  className="w-full bg-[#705AE2] text-white py-4 rounded-lg mt-6 transition duration-300 focus:outline-none focus:ring-2 font-poppins font-bold shadow-md"
                  style={layout?.buttonStyle}
                >
                  {!!activeSubscription
                    ? "UPDATE"
                    : isTrialStart
                      ? "PROCEED WITH TRIAL"
                      : "APPROVE PAYMENT"}
                </button>
              </div>
            </div>
          )}
        {/* Debugging Information */}
      </div>
    </div>
  );
};

export default SubscriptionPayment;
