import React, { useState } from "react";
import {useFintechLayouts} from '../services/AsaApiServices'
interface ConfirmDialogCVVProps {
  isOpen: boolean;
  title: string;
  message: string;
  onCancel: () => void;
  onConfirm: (cvv: string) => void; // Callback for confirming with CVV
}

const ConfirmDialogCVV: React.FC<ConfirmDialogCVVProps> = ({
  isOpen,
  title,
  message,
  onCancel,
  onConfirm,
}) => {
  const [cvvInput, setCVVInput] = useState("");
  const [cvvError, setCVVError] = useState("");
  const layout=useFintechLayouts()
  const handleConfirm = () => {
    if (cvvInput.length === 3 || cvvInput.length === 4) {
      onConfirm(cvvInput); // Pass the CVV input to the onConfirm callback
      setCVVInput(""); // Clear the CVV input after confirmation
      setCVVError(""); // Clear any existing error message
    } else {
      setCVVError("CVV must be 3 or 4 characters long.");
    }
  };
  const onCVVchange=(val:string)=>{
   
    if(val && val.length>0 && isNaN(parseInt(val))){
      setCVVError("CVV should contain only digits");
      return
    }
    setCVVInput(val);
    setCVVError("")
  }
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mx-4">
        <div className="mb-4">
          <label
            className="block text-[#4E4E4E] font-poppins text-sm mb-1"
            htmlFor="cvvInput"
          >
            Please Enter CVV
          </label>
          <input
            id="cvvInput"
            maxLength={4}
            type="password"
            className={`border-2 border-gray-300 rounded-md mt-4 text-[#4E4E4E] py-2 px-3 w-full focus:outline-none focus:ring-2 focus:ring-gray-500 ${
              cvvError ? "border-red-500" : ""
            }`}
            value={cvvInput}
            onChange={(e) => {
              onCVVchange(e.target.value);
            }} // Mask the CVV value
          />
          {cvvError && <p className="text-red-500 text-xs mt-1">{cvvError}</p>}
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onCancel}
            className="py-2 px-4 bg-gray-500 text-white rounded-lg font-poppins"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="py-2 px-4 bg-[#6911D5] text-white rounded-lg font-poppins"
            style={layout?.buttonStyle}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialogCVV;
