import React, {  useState, useContext } from "react";
import arrowBack from "../../assets/Images/arrow_back_ios_new.png";
import kidpay_logo from "../../assets/Images/kidpay_logo.svg";

import { AsaStateContext } from '../../components/AsaStateProvider'

import { useIsFetching,useIsMutating } from "react-query";
import { useDispatch  } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setSessionData,setSessionIDStore,setAsaConsumerCode,setCOnsumerAdress
} from "../../redux/sessionSlice";
import { Config } from "../../Utils/AppConstants";

import kidpay_basic_ico from "../../assets/Images/kidpay_basic.svg";
import kidpay_plus_ico from "../../assets/Images/kidpay_plus.svg";
import kidpay_premium_ico from "../../assets/Images/kidpay_premium.svg";

import LoaderComponent from "../../components/LoaderComponent";
import "./KidPay.css"; // Import your CSS file for animations
import {useAsaSession,useAsaSessionPagesMutation,useIsLoading} from '../../services/AsaSessionServices'
import {useActiveSubscriptions,useSubscriptionsPlans,
  useSubscriptionPurchaseMutation,getCheckOutSessionPages,useFintechLayouts} from '../../services/AsaApiServices'
import { IAsaSession, SessionPage,Plan, SubscriptionDetail } from "../../types/types";
import {showCustomToast} from '../../services/toastService'

import bars_home from "../../assets/Images/bars_home.svg";

import {PlanSlider ,IPlanSlide} from"../../components/PlanSlider"


function mapSubscriptionPlan (plan:Plan,subscriptions:SubscriptionDetail[] | undefined):IPlanSlide {

  var isSubscribed=subscriptions?.some(x=> x.asaFintechCode ===plan.asaFintechCode && x.subscriptionPlanCode===plan.subscriptionPlanCode )
  const trialPrefix=plan.freeTrialPeriod>0?`New users get a free ${plan.freeTrialPeriod}-day trial!`:"No trial period available !"
  const slide:IPlanSlide=
  {
    category:plan.subscriptionTitle,
    icon: kidpay_basic_ico,
    title: "Choose a plan that works for your family.",

    planCode:plan.subscriptionPlanCode,
    description: trialPrefix+ " Cancel or change your subscription at any time.",
    //buttonText:isSubscribed ? "Manage Plan" : `${plan.oneTimeAmount}$/MONTH`
    buttonText:`${plan.oneTimeAmount}$/MONTH`
  }
    switch(plan.subscriptionTitle){
      case "PLUS":
        slide.icon= kidpay_plus_ico
        break;
      case "PREMIUM":
        slide.icon= kidpay_premium_ico
        break;
      default:  //incl basic
        break;
    }
    
    return slide;
}
const KidPayLanding = () => {
  const [state, ] = useContext(AsaStateContext)
  //to remove , because it's used only for backward comp

  const [isLoading,setIsLoading]=useIsLoading()
  const {data:session}=useAsaSession()
  const {data:subscriptions}=useActiveSubscriptions()
  const {data:subscriptionsPlans}=useSubscriptionsPlans()
  const sessionMutation= useAsaSessionPagesMutation()
  
  const layout=useFintechLayouts()
  const subscriptionPurchaseMutation= useSubscriptionPurchaseMutation()
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
 
const slides:IPlanSlide[] | undefined=subscriptionsPlans?.map((x)=> mapSubscriptionPlan(x,subscriptions));

  // Handling Update Session Error
 

  const onBackButtonClick = () => {

      if (session) {
        const sessionPages = session.sessionPages;
        let backActionDynamicLink = null;
        if(!sessionPages ){
          return;
        }
        const targetPage = sessionPages?.find(page => page.pageNumber === 0);
        if (targetPage) {
            const pageData = JSON.parse(targetPage.pageData);
            backActionDynamicLink = pageData.backActionDynamicLink;
        }

        if (backActionDynamicLink) {
          window.location.href = backActionDynamicLink;
        } else {
          console.error("Back action dynamic link not found.");
        }
      } else {
        console.error("UpdatedLocalData is not available.");
      }
    
  };

  const saveFreeSubscription = async (plancode:number) => {
    const plan=subscriptionsPlans?.find(x=>x.subscriptionPlanCode===plancode)
    try {
      setIsLoading(true)
      const requestData = {
        comment: "Free Plan",
        billingEmail: null,
        isAcceptTerms: true,
        firstName: null,
        middleName: null,
        lastName: null,
        subscriptionPlanCode: plan?.subscriptionPlanCode,
        address: null,
        address2: null,
        city: null,
        state: null,
        country: null,
        postalCode: null,
        couponCode: null,
      };
      await subscriptionPurchaseMutation.mutateAsync(requestData)

    } catch (error) {
      console.error(error);
      setIsLoading(false)
      showCustomToast("Error select subscriptions",true)
    }
  }
  

  const  onSubscriptionClick=async (plancode:number)=> {
    
    const plan=subscriptionsPlans?.find(x=>x.subscriptionPlanCode===plancode)
    const matchedActiveSubscription = subscriptions?.find((activeSub) => {
      return subscriptionsPlans?.some(
        (x) =>
          activeSub.asaFintechCode === x.asaFintechCode &&
          activeSub.subscriptionPlanCode === x.subscriptionPlanCode
      );
    });


    if(plan?.isConsumerEligibleForTrial && plan?.isFree){
      await saveFreeSubscription(plan.subscriptionPlanCode)
      return
    }

    
    try {
      setIsLoading(true)
      const sessionToUpdate:IAsaSession  ={...session!}
      sessionToUpdate.sessionPages=undefined
      const sessionpages:SessionPage[]=[]
      const selectPackagepage:SessionPage={
         pageNumber:2,
         pageName: "GOALS PLANS",
         pageTitle: "Select Package",
         pageDescription: "Select Package",
         pageUrl: "/kidpay",
         pageStatusMessage: "INCOMPLETE",
         asaSessionCode:session?.asaSessionCode??"",
         //pageData:JSON.stringify(selectedPlan?.plan)
         pageData:JSON.stringify(plan),
         pageStatus:"INCOMPLETE"
      }
      sessionpages.push(selectPackagepage)
      
      const landingPage:SessionPage={
        pageNumber:81,
        pageName:'Landingyes',
        pageTitle:'Landingyes',
        pageDescription:'Landingyes',
        pageUrl:'/kidpay',
        asaSessionCode:session?.asaSessionCode??"",
        pageData: JSON.stringify({Landing: "true" }),
        pageStatus:"INCOMPLETE",
        pageStatusMessage: "INCOMPLETE",
      }
      sessionpages.push(landingPage)
      
      
      const {pages:checkoutPages,billingAddresses}=await getCheckOutSessionPages(state)
      const pages=sessionpages.concat(checkoutPages)

      const response= await sessionMutation.mutateAsync({asaSession:sessionToUpdate,extraPages:pages})
      //for bakward compatibility
      dispatch(setSessionData({data:response}))
      dispatch(setSessionIDStore(state.sessionID));
      dispatch(setAsaConsumerCode(state.asaConsumerCode));
      dispatch(setCOnsumerAdress({data:billingAddresses}));
      Config.token=state.token || Config.token
      Config.X_ASA_version=state.xAsaVersion || Config.X_ASA_version
      if(matchedActiveSubscription){
        //Config.isActiveSubscription=true
        navigate("/manageSubscriptions");
        
      }else{
        //navigate('/portfoliopilot')
        navigate('/subscriptionpayment')
        
      }
    } catch (error) {
     

      console.error("Error fetching session:", error);
      showCustomToast("Error processing checkouts",true)
    }
    finally{
      setIsLoading(false)
    }
  }


  return (
    <div className="bg-[#121212] flex justify-center items-center h-screen">
      {isLoading && <LoaderComponent />}
      <div className="flex flex-col bg-gradient-to-r from-[#05ACEC] to-[#0051C4] w-full h-full overflow-y-auto text-white relative z-20"
        style={layout?.backGroundStyle}
      >
        <div className="flex items-center justify-evenly space-x-8 pt-8 p-3 mb-8">
          <div className="back-button-container-money"
          style= {layout?.buttonStyle }
          >
            <img
              className="back-button"
              src={arrowBack}
              onClick={onBackButtonClick}
              alt="Back"
            />
          </div>
 
          <h1 className="font-poppins ml-3 text-[22px] leading-[28px] tracking-[1px] w-[500px] h-[56px] mt-6">
            Employ your kids
            <br />the right way.
          </h1>

        </div>

        <div className="flex-grow  relative bg-[#121212]  rounded-more text-gray-800 rounded-t-[3rem]   flex flex-col">

        <img
            src={layout?.icon??kidpay_logo}
            className="kidpay-logo-img"
            alt="Flash"
          />
          <div className=" flex flex-col  p-6 shadow-lg">
            <span className="font-semibold relative -top-4 font-poppins text-[18px] ml-20 text-white">
              {layout?.appName??"KidPay"}
            </span>
            { slides && slides.length>0 &&
              <div className="flex flex-col">
                <PlanSlider
                  slides={slides}
                  onSubscriptionClick={onSubscriptionClick}
                  layout={layout}
                  hideButtonTextUnderContinue={true}
                />
                
              </div>
            }
          </div>
          {/*
          <div className="flex flex-col flex-grow items-center">
                <img src={bars_home} className="flex kidpay-barshome-img" alt="____"/>
          </div>       
          */}


          {/* Enhanced Popup Modal */}
          {/*showPopup && (
            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ease-in-out">
              <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm mx-4 sm:mx-8 lg:mx-12">
                <div className="flex flex-col items-center">
                  <div className="text-center mb-4">
                    <p className="font-poppins text-xl text-gray-800">
                      {popupMessage}
                    </p>
                  </div>
                  <button
                    className="bg-[#006BD2] text-white px-4 py-2 rounded-md font-semibold hover:bg-[#169b6b] transition-colors duration-200"
                    onClick={() => setShowPopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )*/}
        </div>
      </div>
    </div>
  );
};

export default KidPayLanding;
