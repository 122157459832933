import React , {useState} from "react";
import edit_icon from "../assets/Images/edit.png";
import delete_icon from "../assets/Images/delete.png";
import close_icon from "../assets/Images/close_small_portfolio.svg";
import more_vert from "../assets/Images/more_vert_portfolio.svg";
import pwa_image from "../assets/Images/pwa_image.png";
import visa from "../assets/Images/visa.png";
import city_double_cash from "../assets/Images/city_double_cash.png";
import check_everyday from "../assets/Images/check_everyday.png";
import { PaymentMethod} from "../types/types";
import { useNavigate } from "react-router-dom";
import {usePaymentMethodsDeleteMutation} from '../services/AsaApiServices'
import ConfirmDialog from "../components/ConfirmDialog ";

interface PaymentMethodCardProps {
  method: PaymentMethod;
  isSelected: boolean;
  isSelectable: boolean;
  currentCVV: string;
  onSelect: (method: PaymentMethod) => void | undefined;

  onCVVChange: (e: React.ChangeEvent<HTMLInputElement>, paymentMethodId: number) => void;

}
export const getImageSource = (type: string) => {
    switch (type) {
      case "ACH":
        return check_everyday;
      case "Bank":
        return check_everyday; // Assuming Bank uses the same image as check_everyday
      case "Visa":
        return visa;
      case "CC":
        return city_double_cash;
      default:
        return pwa_image;
    }
  };

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({
  method,
  isSelected,
  isSelectable,
  currentCVV,
  onSelect,
  onCVVChange


}) => {
    const [moreOpen, setMoreOpen] = useState(false);
    const navigate = useNavigate();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const paymentDeleteMutation=usePaymentMethodsDeleteMutation()
    const handleConfirmedDelete = async () => {
        try {
            const requestData={
            asaPaymentMethodCode: method?.asaPaymentMethodCode,
            } 
            var response=await paymentDeleteMutation.mutateAsync(requestData)
            //setShowConfirmDialogPayment(false);
        } catch (error) {
            //showSubscriptionToast(error?.message, true);
            console.error("Error deleting billing address:", error);
        }
    }
    const handleCVVBlur = () => {

    };
  
    const handleCVVClick = (e: React.MouseEvent) => {
      e.stopPropagation();
    };
  return (
    <>
    {showConfirmDialog && 
        <ConfirmDialog
        title="Delete Payment Method"
        message="Are you sure you want to delete this payment method?"
        onCancel={() => setShowConfirmDialog(false)}
        onConfirm={handleConfirmedDelete} // Ensure this function is called on confirmation
        isOpen={showConfirmDialog}
        />
    }
    <div
      className={`flex items-center justify-between p-2 rounded-lg cursor-pointer mb-3`}
      style={{
        backgroundColor: isSelected && isSelectable? "#2c2c2c" : "transparent",
        boxShadow: isSelected && isSelectable? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "none",
      }}
    >
       <div className="flex items-center space-x-2">     
        {isSelectable &&
            
            <div
                className="form-radio-outer circle-icon-pportfolio"
                onClick={() => onSelect(method)}
                >
                <div
                    className={`circle-pport ${
                    isSelected ? "circle-pport-large" : "circle-pport-small"
                    }`}
                />
            </div>
        }
        <div className="bg-white rounded p-1">
          <img
            src={getImageSource(method.paymentType)}
            alt={method.paymentType}
            className="w-8 h-5 rounded"
            style={{ objectFit: "contain" }}
          />
        </div>
        <span className="mt-name-portfolio truncate-portfolio text-[#FFFFFF]">
          {method?.paymentMethodName}
        </span>

        {(method.paymentType === "CC" && isSelected) ? (
          <input
            type="password"
            placeholder="CVV"
            value={currentCVV || ""}
            maxLength={4}
            onChange={(e) => onCVVChange(e, method?.paymentMethodId)}
            onBlur={handleCVVBlur}
            onClick={handleCVVClick}
            inputMode="numeric"
            style={{
              backgroundColor: "#616161",
              borderRadius: "10px",
            }}
            className="w-20 h-8 p-2 rounded font-poppins text-[#F1F1F1] text-base leading-6 outline-none text-center box-border input-bg-gray"
          />
        ) : (
          <div className="font-poppins text-[#F1F1F1] font-semibold text-base leading-24">
            ••••{" "}
            {method.paymentType === "CC"
              ? method?.last4DigitsOfCardNumber
              : method?.last4DigitsOfAccountNumber}
          </div>
        )}
      </div>

      {isSelected && (
        <>
          {moreOpen ? (
            <div className="flex items-center space-x-2 ml-auto">
              <button
                className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                onClick={() => {
                    navigate("/addcreditcard", { state: { payment: method,returnUrl:"/subscriptionpayment" }});
                }}
              >
                <img
                  className="w-6 h-6 cursor-pointer"
                  src={edit_icon}
                  alt="Edit"
                />
              </button>
              <button
                className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                onClick={()=>setShowConfirmDialog(true)}
              >
                <img
                  className="w-6 h-6 cursor-pointer"
                  src={delete_icon}
                  alt="Delete"
                />
              </button>
              <button
                className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                onClick={() => setMoreOpen(false)}
              >
                <img
                  className="w-6 h-6 cursor-pointer"
                  src={close_icon}
                  alt="Close"
                />
              </button>
            </div>
          ) : (
            <div className="flex items-center space-x-2 ml-auto">
              <button
                className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                onClick={() => setMoreOpen(true)}
              >
                <img
                  className="w-6 h-6 cursor-pointer"
                  src={more_vert}
                  alt="More"
                />
              </button>
            </div>
          )}
        </>
      )}
    </div>
    </>
  );
};

export default PaymentMethodCard ;
