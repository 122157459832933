import { env } from '../Utils/env'

import  {  useEffect,useContext, useState,SetStateAction } from "react";
import { setBoolFn,IAsaSessionState,IAsaSession,SessionPage,IAsaSecureSession} from '../types/types'
import { AsaStateContext } from '../components/AsaStateProvider'
import {useAsaQuery,callApiPut, callApiPost, callApiGet,prefetchFintechLayout } from './AsaApiServices'
import {AsaLoadingContext} from '../components/AsaSessionContext'
import {UseQueryResult, QueryClient,  useQuery, useQueryClient,
  useMutation,UseMutationResult,useIsFetching,useIsMutating } from "react-query";
import { Config } from '../Utils/AppConstants';

const STORAGE_KEY="AsaSecureSession"

function useAsaSession(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined):UseQueryResult<IAsaSession, any>{
    const [state, setState] = useContext(AsaStateContext)
  
    useEffect(()=>{
        const currentUrl = new URL(window.location.href);
        if(!currentUrl.searchParams || !currentUrl.searchParams.size || currentUrl.searchParams.size===0) 
          return;
    
        const newSessionId=parseInt(currentUrl.searchParams.get("sessionid")|| '')
        if(isNaN(newSessionId)){
          return
        }
   
        const isSessionChanged=newSessionId!==state.sessionID
        const newstate:IAsaSessionState = isSessionChanged?{} as IAsaSessionState:{ ...state }
        if(isSessionChanged){
          setState({} as IAsaSessionState)  //reset state
          console.log("session changed")
        }
        newstate.sessionID = newSessionId
        newstate.asaConsumerCode = parseInt(currentUrl.searchParams.get("asaConsumerCode") || '') || newstate.asaConsumerCode;
        newstate.versionCode = parseFloat(currentUrl.searchParams.get("version") || '') || newstate.versionCode || 1.09;
        newstate.token = currentUrl.searchParams.get("token") || newstate.token;
        newstate.asaFintechcode = parseInt(currentUrl.searchParams.get("asaFintechCode") || '') || newstate.asaFintechcode;
        newstate.xAsaVersion=newstate.versionCode 
        
        if(JSON.stringify(state)!==JSON.stringify(newstate)){
            setState(newstate)
           
        }
    },[window.location.href])
    const res=useAsaQuery<IAsaSession>(['Session',state.sessionID],
      env.REACT_APP_API_URL + `AsaSession?asaSessionCode=${state.sessionID}`
      ,setLoading,setError,
      (v)=>!!(state.sessionID && state.sessionID)
    )
    
    return res;
    
  }
  function useAsaSession_old(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined){
    const [state, ] = useContext(AsaStateContext)
    
    const res=useAsaQuery(['Session',state.sessionID],
      env.REACT_APP_API_URL + `AsaSession?asaSessionCode=${state.sessionID}`
      ,setLoading,setError,
      (v)=>!!(state.sessionID && state.sessionID)
    )
    
    return res;
    
  }
  export const updateSessionPages = async( asaState:IAsaSessionState,toUpdate:IAsaSession,extraPages:SessionPage[] | null=null )=>{
    const toUpdateclone={...toUpdate}
    if(toUpdate.sessionPages  ){ 
      toUpdateclone.sessionPages=[...toUpdate.sessionPages]
    }
    if(extraPages){  // we are in mode just add new pages
      toUpdateclone.sessionPages=[...extraPages]
      var pagecount=parseInt(toUpdateclone.pageCount)
      toUpdateclone.sessionPages.forEach((page,idx)=>{
        if(!page.pageNumber){
          page.pageNumber=(pagecount-1+idx).toString()
        }
      })
      toUpdateclone.pageCount+=extraPages.length
    }
    try {
      const response = await callApiPut(
        env.REACT_APP_API_URL + `AsaSession`,
        asaState,
        toUpdateclone
      );
      return response;
    } catch (error) {
      throw error;
    }
    finally {
     
    }
  }
  interface ISessionUpdateArgs{
    asaState:IAsaSessionState,
    data: any,
    pageNumber: number,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string,
    pageCount:number,
    statusMessage:string,
    sessionName:string ,
    status:string,
    pageStatus:string
  }
  export const updateSession = async (
    asaState:IAsaSessionState,
    data: any,
    pageNumber: number,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string,
    pageCount:number,
    statusMessage:string,
    sessionName:string ="ASAVAULT-ONEGOAL",
    status:string ="Incomplete",
    pageStatus:string ="Complete"
  ) => {
  
   
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: `${asaState.sessionID}`,
      asaConsumerCode: asaState.asaConsumerCode??0,
      version: asaState.versionCode.toString(),
      sessionName: sessionName,
      pageCount: pageCount,
      status: status,
      statusMessage: statusMessage,
      sessionPages: [
        {
          asaSessionCode: `${asaState.sessionID}`, // Use the session ID
          pageNumber: pageNumber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: pageStatus, // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };
  
    try {
  
      const response = await callApiPut(
        env.REACT_APP_API_URL + `AsaSession`,
        asaState,
        updateRequest
      );
     
      return response;
    } catch (error) {
      throw error;
    }
    finally {
     
    }
  };
  interface IUpdateSessionPagesArg{
    asaSession:IAsaSession
    extraPages:SessionPage[] | null
  }
  function useAsaSessionPagesMutation( ):UseMutationResult<any, any, IUpdateSessionPagesArg, any>{
    const  [state, ] = useContext(AsaStateContext)
    const queryclient =useQueryClient()
    const mutation=useMutation<any,any,IUpdateSessionPagesArg,any>({
      mutationFn: (arg:IUpdateSessionPagesArg) =>
         updateSessionPages(state,arg.asaSession,arg.extraPages),
        //TO do validate backend, potentially queryclient.setquerydata(.....)
        onSuccess:(response) =>invalidateAsaSession(queryclient,state.sessionID??0,response)
      })
    return mutation;
  }
  
  function useAsaSessionMutation( ):UseMutationResult<any, any, ISessionUpdateArgs, any>{
    const  [state, ] = useContext(AsaStateContext)
    const queryclient =useQueryClient()
    const mutation=useMutation<any,any,ISessionUpdateArgs,any>({
      mutationFn: (updateSessionArgs:ISessionUpdateArgs  ) =>
         updateSession(
          state,
          updateSessionArgs.data,
          updateSessionArgs.pageNumber,
          updateSessionArgs.pageName,
          updateSessionArgs.pageTitle,
          updateSessionArgs.pageDescription,
          updateSessionArgs.pageUrl,
          updateSessionArgs.pageStatusMessage,
          updateSessionArgs.pageCount,
          updateSessionArgs.statusMessage,
          updateSessionArgs.sessionName,
          updateSessionArgs.status,
          updateSessionArgs.pageStatus,
        ),
        //TO do validate backend, potentially queryclient.setquerydata(.....)
        onSuccess:(response) =>invalidateAsaSession(queryclient,state.sessionID??0,response)
      })
    return mutation;
  }
  const invalidateAsaSession=(queryclient:QueryClient | null,sessionId:number,response:any )=>{
    const queryKey=['Session',sessionId]

    if(!response){
      queryclient?.invalidateQueries({queryKey:queryKey})
      queryclient?.refetchQueries({queryKey:queryKey})
    }
    else{
      queryclient?.setQueriesData(queryKey,response)
    }
  }
  function generateRandomString() {
    var array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
}
// Calculate the SHA256 hash of the input text. 
// Returns a promise that resolves to an ArrayBuffer
function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

// Base64-urlencodes the input string
function base64urlencode(str) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str))).replace('=', '')
  // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  //return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
  //    .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

// Return the base64-urlencoded sha256 hash for the PKCE challenge
async function pkceChallengeFromVerifier(v) {
  const hashed = await sha256(v);
  return base64urlencode(hashed);
}
function useIsLoading ():[boolean,React.Dispatch<SetStateAction<boolean>>]{
  const [isContecxLoading,setIsContecxLoading ] = useContext(AsaLoadingContext)

  const isFetching = useIsFetching()
  const isMutating =useIsMutating()
  const isLoading=isFetching>0 || isMutating>0 || isContecxLoading
  
  return [isLoading,setIsContecxLoading]
}
function useMainAsaSession(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined):IAsaSecureSession{
    const [state, setState] = useContext(AsaStateContext)
    const [secureSession,setSecureSesssion]=useState<IAsaSecureSession>(JSON.parse(localStorage.getItem(STORAGE_KEY) || '0') || {}  )
    const queryClient=useQueryClient()
    useEffect(()=>{
        const currentUrl = new URL(window.location.href);
        if(!currentUrl.searchParams || !currentUrl.searchParams.size || currentUrl.searchParams.size===0) 
          return;
        
        const load=async()=>{
                
          const newSessionId=parseInt(currentUrl.searchParams.get("sessionid")|| '')
          if(isNaN(newSessionId)){
            return
          }
          const isSessionChanged=newSessionId!==state.sessionID
          const newstate:IAsaSessionState = isSessionChanged?{} as IAsaSessionState:{ ...state }
          if(isSessionChanged){
            setState({} as IAsaSessionState)  //reset state
            setSecureSesssion({} as IAsaSecureSession)
            localStorage.setItem(STORAGE_KEY,JSON.stringify({}))
          }
          newstate.sessionID = newSessionId

          if(!newstate.codeVerifier || !newstate.codeChallenge){
            newstate.codeVerifier=generateRandomString();
            newstate.codeChallenge= await pkceChallengeFromVerifier(newstate.codeVerifier);
          }
          newstate.url=window.location.href
          newstate.asaConsumerCode = parseInt(currentUrl.searchParams.get("asaConsumerCode") || '') || newstate.asaConsumerCode;
          newstate.versionCode = parseFloat(currentUrl.searchParams.get("version") || '') || newstate.versionCode || 1.08;
          newstate.token = currentUrl.searchParams.get("token") || newstate.token;
          newstate.asaFintechcode = parseInt(currentUrl.searchParams.get("asaFintechCode") || '') || newstate.asaFintechcode;
          newstate.xAsaVersion=newstate.versionCode
          Config.X_ASA_version=newstate.versionCode
          Config.token= newstate.token || Config.token
          
          if(JSON.stringify(state)!==JSON.stringify(newstate)){
              
              setState(newstate)
          }
        }
        load()
    },[window.location.href])

    
    useEffect(()=>{
      //console.log("start effect",state.sessionID)
      //setIsLoading(false)
      //prefetchFintechLayout(state,queryClient)
      const loadSecureSession=async ()=>{
        console.log("useMainAsaSession loadSecureSession")     
        try
        {
          setLoading?.(true)
          const validateBody={asaSessionCode:state.sessionID?.toString(),codeChallenge:state.codeChallenge,asaConsumerCode:state.asaConsumerCode}
          const basePublicUri=env.REACT_APP_API_URL?.replace('/asaconnect/','/public/')
          //var sessionPreValidate=await callApiPost(env.REACT_APP_API_URL+'AsaSession/SessionValidate',state,validateBody,false)
          var sessionPreValidate=await callApiPost(basePublicUri+'SessionValidate',state,validateBody,false)
          //var secureSession=await callApiGet<IAsaSecureSession>(env.REACT_APP_API_URL + `AsaSession/SessionParam?asaSessionCode=${state.sessionID}&codeVerifier=${state.codeVerifier}`,state)
          var getSecureSession=await callApiGet<IAsaSecureSession>(basePublicUri + `SessionParam?asaSessionCode=${state.sessionID}&codeVerifier=${state.codeVerifier}`,state)
          setSecureSesssion(getSecureSession)
          localStorage.setItem(STORAGE_KEY,JSON.stringify(getSecureSession))
          if(getSecureSession.tokenTag && getSecureSession.subscriptionKey){

            const newstate:IAsaSessionState = { ...state }
            newstate.token=getSecureSession.tokenTag
            newstate.subscriptionKey=getSecureSession.subscriptionKey
            newstate.asaFintechcode=getSecureSession.asaFintechcode ?? newstate.asaFintechcode
            newstate.appCode=getSecureSession.fintechApplicationCode ?? newstate.appCode
            Config.asaFintechCode=getSecureSession.asaFintechCode ?? newstate.asaFintechcode
            Config.appCode=getSecureSession.appCode ?? newstate.appCode
            Config.token=getSecureSession.tokenTag
            Config.subscriptionKey=getSecureSession.subscriptionKey
            

            if(!!!env.REACT_APP_SUBSCRIPTION){
              env.REACT_APP_SUBSCRIPTION=getSecureSession.subscriptionKey
            }
            if(JSON.stringify(state)!==JSON.stringify(newstate)){
              setState(newstate)
            }
          }
          
        }
        catch(err){
          console.log(err)
         
        }
        finally{
          setLoading?.(false)
        }
      }
      const currentUrl = new URL(window.location.href);
      let isTokenObtained=!!currentUrl.searchParams.get("token") || !!state.token;


      if(!isTokenObtained && 
          ( state.sessionID 
          && state.codeChallenge 
          && (secureSession?.asaSessionCode!==state.sessionID?.toString() || !secureSession?.tokenTag)
          )
        ){
          loadSecureSession()
        }
    },[state.sessionID,state.codeVerifier,state.codeChallenge,state.token])
   
    return secureSession;
  }
function useBackButton():()=>void{
  const {data:session}=useAsaSession()
  const handleBackClick = () => {

    const targetPage = session?.sessionPages?.find(page => page.pageNumber === 0);
    if (targetPage) {
      const pageData = JSON.parse(targetPage.pageData);
      const backActionDynamicLink = pageData.backActionDynamicLink;
      if (backActionDynamicLink) {
        window.location.href = backActionDynamicLink;
      } else {
        console.error("Back action dynamic link not found.");
      }
    }

  };
  return handleBackClick
}
export {useAsaSession,useAsaSessionPagesMutation,useAsaSessionMutation,useMainAsaSession,useIsLoading,useBackButton}