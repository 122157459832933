import React,{useState} from "react";
import edit_icon from "../assets/Images/edit.png";
import delete_icon from "../assets/Images/delete.png";
import close_icon from "../assets/Images/close_small_portfolio.svg";
import more_vert from "../assets/Images/more_vert_portfolio.svg";
import { BillingAddress } from "../types/types";
import { useNavigate } from "react-router-dom";
import {useBillingAddresseslMutation} from '../services/AsaApiServices'
import ConfirmDialog from "../components/ConfirmDialog ";
interface BillingAddressCardProps {
  address: BillingAddress;
  isSelected: boolean;
  isSelectable: boolean;
  onSelect: (address: BillingAddress) => void | undefined;
 
}

const BillingAddressCard: React.FC<BillingAddressCardProps> = ({
  address,
  isSelected,
  isSelectable,
  onSelect,

}) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false );
  const navigate = useNavigate();
  const billinAddressMutation=useBillingAddresseslMutation()
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const handleConfirmedDelete = async () => {
      try {
        const billingAddressToDelete =address;
       
        const requestData = [
          {
            consumerAddressId: billingAddressToDelete.consumerAddressId,
            address1: billingAddressToDelete.address1,
            city: billingAddressToDelete.city,
            state: billingAddressToDelete.state,
            zipCode: billingAddressToDelete.zipCode,
            firstName: billingAddressToDelete.firstName,
            lastName: billingAddressToDelete.lastName,
            country: billingAddressToDelete.country,
            isCurrent: false,
            isActive: false,
            moveoutDate: null,
            isBilling: true,
            isPrimary: false,
            isShipping: false,
            addressType: null,
          },
        ];

        try {
           await billinAddressMutation.mutateAsync(requestData)
        } catch (error) {
            console.log(error)
        } finally {
          
        }
      } catch (error) {
        console.error("Error deleting billing address:", error);
      }
    
  };

  return (
    <>
    {showConfirmDialog && (
    <ConfirmDialog
        title="Delete Billing Address"
        message="Are you sure you want to delete this billing address?"
        onCancel={() => {
        setShowConfirmDialog(false);
        
        }}
        onConfirm={() => {
        // Handle confirm logic here
        setShowConfirmDialog(false); // Close dialog after action
        handleConfirmedDelete();
    }}
    isOpen={showConfirmDialog}
/>
)}
    <div
      className="mt-3 rounded-lg p-2 flex items-center justify-between"
      onClick={() => {
        if(isSelectable)
            onSelect?.(address)
      }}
      style={{
        backgroundColor: isSelected && isSelectable? "#2c2c2c" : "transparent",
        boxShadow: isSelected && isSelectable? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "none",
      }} 
    >
      <div className="flex items-center space-x-2 p-2">
        {isSelectable && 
        <div className="form-radio-outer circle-box-outer-pportfolio">
          <div
            style={{
              width: isSelected ? "8px" : "0",
              height: isSelected ? "8px" : "0",
              borderRadius: "50%",
              backgroundColor: "#6B46C1",
              transition: "width 0.2s, height 0.2s",
            }}
          />
        </div>
        }
        <span className="text-[#FFFFFF] ml-4 text-style-bold-pportfolio">
          {address.firstName} {address.lastName}, {address.address1}
          <br />
          <span className="text-[#FFFFFF] font-poppins font-semibold text-style-normal-pportfolio">
            {address.city}, {address.state}, {address.zipCode}, {address.country}.
          </span>
        </span>
      </div>

      <div className="flex items-center space-x-2">
        {isEditMode && isSelected ? (
          <>
            <button
              className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/billingaddresses", { state: { address: address } });
              }}
            >
              <img className="w-6 h-6 cursor-pointer" src={edit_icon} alt="Edit" />
            </button>
            <button
              className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
              onClick={(e) => {
                e.stopPropagation();
                //onDelete?.(address);
                setShowConfirmDialog(true);
              }}
            >
              <img className="w-6 h-6 cursor-pointer" src={delete_icon} alt="Delete" />
            </button>
            <button
              className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
              onClick={(e) => {
                e.stopPropagation();
                setIsEditMode(!isEditMode);
              }}
            >
              <img className="w-6 h-6 cursor-pointer" src={close_icon} alt="Close" />
            </button>
          </>
        ) : (
          isSelected && (
            <button
              className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
              onClick={(e) => {
                e.stopPropagation();
                setIsEditMode(!isEditMode);
              }}
            >
              <img className="w-6 h-6 cursor-pointer" src={more_vert} alt="More Options" />
            </button>
          )
        )}
      </div>
    </div>
    </>
  );
};

export default BillingAddressCard;