import React, {useState} from "react";
import dropdown_arrow from "../../assets/Images/arrow_drop_down_white.svg";
import add_icon from "../../assets/Images/add.png";
import "./SubscriptionPayment.css";
interface DropdownProps<T> {
  items: T[];
  selectedItem: T | undefined;
  onSelect: (item: T) => void;
  renderItem: (item: T, isSelected: boolean,isSelectable: boolean) => React.ReactNode;
  addButtonLabel?: string;
  onAddButtonClick?: () => void;
}

const Dropdown = <T,>({
  items,
  selectedItem,
  onSelect,
  renderItem,
  addButtonLabel,
  onAddButtonClick,
}: DropdownProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div className="flex items-center justify-between mt-5 mx-2">
        <p className="payment-heading-list-portfolio font-poppins font-semibold">
          Select or add an option.
        </p>
        <div className="image-container">

        <img
            className={`drop-down-img cursor-pointer ${!isOpen ? "rotate-180" : ""}`}
            src={dropdown_arrow}
            alt="Dropdown"
            onClick={()=>setIsOpen(!isOpen)}
          />
        </div>
      </div>

      {!isOpen && selectedItem && (
        <div className="space-y-2 mt-2">
          {renderItem(selectedItem, true,false)}
        </div>
      )}

      {isOpen && (
        <div className="space-y-2 mt-2 max-h-40 overflow-y-auto">
          {items.map((item, index) => {
            const isSelected = item === selectedItem;
            return (
              <div key={index} onClick={() => {
                onSelect(item)
                setIsOpen(false)
                }}>
                {renderItem(item, isSelected,true)}
              </div>
            );
          })}
        </div>
      )}

      {isOpen && addButtonLabel && onAddButtonClick && (
        <button
          className="custom-button-addpayment mt-3 font-poppins mx-10 mb-5"
          onClick={onAddButtonClick}
        >
          <img
            src={add_icon}
            alt="Add"
            className="inline-block font-poppins mr-2 w-6 h-6"
          />
          <span className="custom-text-addpayment font-semibold">
            {addButtonLabel}
          </span>
        </button>
      )}
    </div>
  );
};

export default Dropdown;