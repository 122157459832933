import  { useState, useEffect,  } from "react";
import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import LoaderComponent from "../../components/LoaderComponent";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import showSubscriptionToast from "./SubscriptionToast"

import {useFintechLayouts, useBillingAddresseslMutation} from '../../services/AsaApiServices'
import {useIsLoading} from '../../services/AsaSessionServices'

interface IAddressInput{
  country: string,
  firstName: string,
  lastName: string,
  streetAddress: string,
  aptSuite: string,
  city: string,
  state: string,
  zipCode: string,
}
const defaultInput:IAddressInput={
  country: "",
  firstName: "",
  lastName: "",
  streetAddress: "",
  aptSuite: "",
  city: "",
  state: "",
  zipCode:""
}
const BillingAddressScreen = () => {
  const billinAddressMutation=useBillingAddresseslMutation()
  const layout=useFintechLayouts()
  const [isLoading,]=useIsLoading()

  //------------------------
  const [inputs, setInputs] = useState({...defaultInput});

  const [errors, setErrors] = useState({...defaultInput});

  const [isFormFilled, setIsFormFilled] = useState(false);
  const location = useLocation();
  const { state } = location;

  // Check if state is defined and extract the address object
  const address = state?.address;
  const returnurl = state?.returnurl??"/subscriptionpayment";
  const navigate = useNavigate();

  const handleInputChange = (e: { target: { id: any; value: any } }) => {
    const { id, value } = e.target;
    setInputs({ ...inputs, [id]: value });
    setErrors({ ...errors, [id]: "" }); // Clear error when user starts typing
  };

  const handleCancel = () => {
    setInputs({...defaultInput});
    setErrors({...defaultInput});
    navigate(-1);
  };

  useEffect(() => {
    setIsFormFilled(
      !!(
        inputs.country &&
        inputs.firstName &&
        inputs.lastName &&
        inputs.streetAddress &&
        inputs.city &&
        inputs.state &&
        inputs.zipCode
      )
    );
  }, [inputs]);

  const handleBackClick = () => {
    navigate(returnurl); // This will navigate back to the previous page in the browser history
  };

  useEffect(() => {
    if (address) {
      setInputs({...inputs,...address})
    } 
  }, [address]);


  const handleSave = async () => {
    // Implement validation checks here
    if (validateForm()) {

        const requestData = [
          {
            consumerAddressId: (address?.consumerAddressId)??0,
            address1: inputs.streetAddress,
            address2: inputs.aptSuite || null,
            city: inputs.city,
            state: inputs.state,
            zipCode: inputs.zipCode,
            isCurrent: false,
            isActive: true,
            moveoutDate: null,
            isBilling: true,
            Country: inputs.country,
            FirstName: inputs.firstName,
            LastName: inputs.lastName,
            isPrimary: false,
            isShipping: false,
            addressType: null,
          },
        ];
        try{
          var res=await billinAddressMutation.mutateAsync(requestData)
          navigate(returnurl); 
        }
        catch(error){
            showSubscriptionToast((error as Error)?.message,true)
        }

    } else {
      console.log("Form validation failed.");
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors: typeof errors = { ...errors };

    // Check for required fields
    (Object.keys(inputs) as Array<keyof typeof inputs>).forEach((key) => {
      if (!inputs[key] && key !== "aptSuite") {
        newErrors[key] = `${key} is required`;
        valid = false;
      }
    });

    // Check if zipCode is numeric
    if (inputs.zipCode && !/^\d+$/.test(inputs.zipCode)) {
      newErrors.zipCode = "Zip code must be numeric";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };


  return (
    <div className="bg-gradient-to-b from-[#705AE2] to-[#490C95] h-screen flex flex-col  text-center pt-10 relative"
      style= {layout?.backGroundStyle }
    >
      {isLoading && <LoaderComponent />}{" "}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <img
            className="w-6 h-6 sm:w-8 sm:h-8 ml-5"
            src={arrow_back_ios_new}
            alt="Back"
            onClick={handleBackClick}
          />
        </div>
        <p className="custom-style-bugs text-center flex-grow">
          Add an Address
        </p>
        <div className="w-6 h-6 sm:w-8 sm:h-8 ml-5"></div>
      </div>
      <div className="w-full  bg-[#121212] rounded-t-3xl shadow-md p-6 mt-10 more-rounded">
        <form className="mt-5">
          <div className="mb-10 relative border-white">
            <label
              className={`absolute transition-all font-poppins font-semibold duration-300 ease-in-out transform left-3 bg-[#121212] px-1 ${
                inputs.country
                  ? "top-[-15px] text-[#FFFFFF] scale-75 label-shrink-address"
                  : errors.country
                    ? "top-[-10px] text-[#FFFFFF] -translate-y-1/2 label-expand"
                    : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand"
              }`}
              htmlFor="country"
              style={{
                fontSize: errors.country
                  ? "1rem"
                  : inputs.country
                    ? "1.25rem"
                    : "1rem", // Font size based on input or error
              }}
            >
              Country
            </label>
            <input
              type="text"
              id="country"
              className={`mt-6 block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-4 pr-3 ${
                inputs.country ? "text-[#FFFFFF]" : ""
              }`}
              placeholder=" " // Ensuring consistent placeholder size
              style={{
                backgroundColor: "#121212",
                borderColor: inputs.country
                  ? "#FFFFFF"
                  : "rgba(255, 255, 255, 0.5)",
                color: "#FFFFFF",
              }}
              value={inputs.country}
              onChange={handleInputChange}
            />
            {errors.country && (
              <p
                className="text-red-500 text-left text-xs absolute"
                style={{ left: "10px" }}
              >
                {errors.country}
              </p>
            )}
          </div>
          <div className="mb-10 relative">
            <label
              className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#121212] px-1 font-poppins ${
                inputs.firstName
                  ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-address"
                  : errors.firstName
                    ? "top-[-10px] text-[#FFFFFF] -translate-y-1/2 label-expand"
                    : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand"
              }`}
              htmlFor="firstName"
              style={{
                fontSize: errors.firstName
                  ? "1rem"
                  : inputs.firstName
                    ? "1.25rem"
                    : "1rem", // Font size based on error or input state
              }}
            >
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              className={`mt-1 block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                inputs.firstName ? "text-[#FFFFFF]" : ""
              }`}
              style={{
                backgroundColor: "#121212",
                borderColor: inputs.firstName
                  ? "#FFFFFF"
                  : "rgba(255, 255, 255, 0.5)",
                color: "#FFFFFF",
              }}
              value={inputs.firstName}
              onChange={handleInputChange}
            />
            {errors.firstName && (
              <p
                className="text-red-500 text-left text-xs absolute"
                style={{ left: "10px" }}
              >
                {errors.firstName}
              </p>
            )}
          </div>

          <div className="mb-10  relative">
            <label
              className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#121212] px-1 font-poppins ${
                inputs.lastName
                  ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-address"
                  : errors.lastName
                    ? "top-[-10px] text-[#FFFFFF] -translate-y-1/2 label-expand"
                    : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand"
              }`}
              htmlFor="lastName"
              style={{
                fontSize: errors.lastName
                  ? "1rem"
                  : inputs.lastName
                    ? "1.25rem"
                    : "1rem", // Font size based on error or input state
              }}
            >
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              className={`mt-1 block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                inputs.lastName ? "text-[#FFFFFF]" : ""
              }`}
              style={{
                backgroundColor: "#121212",
                borderColor: inputs.lastName
                  ? "#FFFFFF"
                  : "rgba(255, 255, 255, 0.5)",
                color: "#FFFFFF",
              }}
              value={inputs.lastName}
              onChange={handleInputChange}
            />
            {errors.lastName && (
              <p
                className="text-red-500 text-left text-xs absolute"
                style={{ left: "10px" }}
              >
                {errors.lastName}
              </p>
            )}
          </div>

          <div className="mb-10 relative">
            <label
              className={`absolute transition-all duration-300 ease-in-out transform label-left bg-[#121212] font-poppins ${
                inputs.streetAddress
                  ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-address left-[-10px]" // Move label further left when shrunk
                  : errors.streetAddress
                    ? "top-[-10px] text-[#FFFFFF] -translate-y-1/2 label-expand"
                    : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand"
              }`}
              htmlFor="streetAddress"
              style={{
                fontSize: errors.streetAddress
                  ? "1rem"
                  : inputs.streetAddress
                    ? "1.25rem"
                    : "1rem",
                zIndex: 1,
                left: inputs.streetAddress ? "5px" : "", // Adjust left positioning when label is shrunk
              }}
            >
              Street Address
            </label>
            <input
              type="text"
              id="streetAddress"
              className={`mt-4 block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                inputs.streetAddress ? "text-[#FFFFFF]" : ""
              }`}
              style={{
                backgroundColor: "#121212",
                borderColor: inputs.streetAddress
                  ? "#FFFFFF"
                  : "rgba(255, 255, 255, 0.5)",
                color: "#FFFFFF",
                paddingTop: "0.75rem", // Adjusted padding for vertical centering
                paddingBottom: "0.75rem", // Ensures equal padding on top and bottom
                lineHeight: "1.25rem", // Line height to help with vertical centering
                boxSizing: "border-box", // Includes padding and border in the element's total width and height
              }}
              value={inputs.streetAddress}
              onChange={handleInputChange}
              placeholder=" " // Placeholder should be empty
            />
            {errors.streetAddress && (
              <p
                className="text-red-500 text-left text-xs absolute"
                style={{ left: "10px" }} // Adjust positioning for error message
              >
                {errors.streetAddress}
              </p>
            )}
          </div>

          <div className="mb-10 relative">
            <label
              className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#121212] font-poppins ${
                inputs.aptSuite
                  ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-address left-[-10px]" // Move label further left when shrunk
                  : errors.aptSuite
                    ? "top-[-0.75rem] text-[#FFFFFF] -translate-y-1/2 label-expand"
                    : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand"
              }`}
              htmlFor="aptSuite"
              style={{
                fontSize: errors.aptSuite
                  ? "1rem"
                  : inputs.aptSuite
                    ? "1.25rem"
                    : "1rem", // Font size based on error or input state
                zIndex: 1, // Ensure label stays above other elements
                left: inputs.aptSuite ? "-2px" : "", // Adjust left positioning when label is shrunk
              }}
            >
              Apt, Suite, etc. (optional)
            </label>
            <input
              type="text"
              id="aptSuite"
              className={`mt-4 block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                inputs.aptSuite ? "text-[#FFFFFF]" : ""
              }`}
              style={{
                backgroundColor: "#121212",
                borderColor: inputs.aptSuite
                  ? "#FFFFFF"
                  : "rgba(255, 255, 255, 0.5)",
                color: "#FFFFFF",
                paddingTop: "0.75rem", // Adjusted padding to center text vertically
                paddingBottom: "0.75rem", // Ensures equal padding on top and bottom
                boxSizing: "border-box", // Includes padding and border in the element's total width and height
              }}
              value={inputs.aptSuite}
              onChange={handleInputChange}
              placeholder=" " // Placeholder should be empty
            />
            {errors.aptSuite && (
              <p
                className="text-red-500 text-left text-xs absolute"
                style={{ left: "10px" }} // Adjust positioning for error message
              >
                {errors.aptSuite}
              </p>
            )}
          </div>

          <div className="mb-10  relative">
            <label
              className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#121212] px-2 font-poppins ${
                inputs.city
                  ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-address"
                  : errors.city
                    ? "top-[-0.75rem] text-[#FFFFFF] -translate-y-1/2 label-expand" // Adjusted position when there's an error
                    : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand"
              }`}
              htmlFor="city"
              style={{
                fontSize: errors.city
                  ? "1rem"
                  : inputs.city
                    ? "1.25rem"
                    : "1rem", // Font size based on error or input state
                zIndex: 1, // Ensure label stays above other elements
              }}
            >
              City
            </label>
            <input
              type="text"
              id="city"
              className={`block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                inputs.city ? "text-[#FFFFFF]" : ""
              }`}
              style={{
                backgroundColor: "#121212",
                borderColor: inputs.city
                  ? "#FFFFFF"
                  : "rgba(255, 255, 255, 0.5)",
                color: "#FFFFFF",
                paddingTop: "0.75rem", // Adjusted padding for vertical centering
                paddingBottom: "0.75rem", // Ensures equal padding on top and bottom
                lineHeight: "1.25rem", // Line height to help with vertical centering
                boxSizing: "border-box", // Includes padding and border in the element's total width and height
              }}
              value={inputs.city}
              onChange={handleInputChange}
              placeholder=" " // Placeholder should be empty to prevent extra space
            />
            {errors.city && (
              <p
                className="text-red-500 text-left text-xs absolute"
                style={{ left: "10px", bottom: "-1.5rem" }} // Adjust positioning for error message
              >
                {errors.city}
              </p>
            )}
          </div>

          <div className="flex mb-10 space-x-4">
            <div className="flex-1 relative">
              <label
                className={`absolute transition-all duration-300 ease-in-out transform left-3 bg-[#121212] px-1 font-poppins ${
                  inputs.state
                    ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-address"
                    : "top-1/2 text-[#4E4E4E] -translate-y-1/2 label-expand"
                }`}
                htmlFor="state"
                style={{
                  fontSize: inputs.state ? "1.25rem" : "0.875rem",
                  color: inputs.state ? "#FFFFFF" : "#FFFFFF",
                  zIndex: 1, // Ensure label stays above other elements
                }}
              >
                State
              </label>
              <input
                type="text"
                id="state"
                className={`block w-full h-[55px] font-poppins rounded-md border-2 border-[#EBECF0] shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                  inputs.state && "text-[#FFFFFF] border-black"
                }`}
                style={{
                  backgroundColor: "#121212",
                  borderColor: inputs.state
                    ? "#FFFFFF"
                    : "rgba(255, 255, 255, 0.5)",
                  color: "#FFFFFF",
                  paddingTop: "0.75rem", // Adjusted padding for vertical centering
                  paddingBottom: "0.75rem", // Ensures equal padding on top and bottom
                  lineHeight: "1.25rem", // Line height to help with vertical centering
                  boxSizing: "border-box", // Includes padding and border in the element's total width and height
                }}
                value={inputs.state}
                onChange={handleInputChange}
                placeholder=" " // Placeholder should be empty to prevent extra space
              />
              {errors.state && (
                <p
                  className="text-red-500 text-left text-xs absolute"
                  style={{ left: "10px", bottom: "-1.5rem" }} // Adjust positioning for error message
                >
                  {errors.state}
                </p>
              )}
            </div>

            <div className="flex-1 relative">
              <label
                className={`absolute transition-all duration-300 ease-in-out transform left-3 bg-[#121212] px-1 font-poppins ${
                  inputs.zipCode
                    ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-address"
                    : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand"
                }`}
                htmlFor="zipCode"
                style={{
                  fontSize: inputs.zipCode ? "1.25rem" : "0.875rem",
                  color: inputs.zipCode ? "#FFFFFF" : "#FFFFFF",
                  zIndex: 1, // Ensure label stays above other elements
                }}
              >
                Zip Code
              </label>
              <input
                type="tel"
                id="zipCode"
                className={`block w-full h-[55px] font-poppins rounded-md border-2 border-[#EBECF0] shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                  inputs.zipCode && "text-[#4E4E4E] border-black"
                }`}
                style={{
                  backgroundColor: "#121212",
                  borderColor: inputs.zipCode
                    ? "#FFFFFF"
                    : "rgba(255, 255, 255, 0.5)",
                  color: "#FFFFFF",
                  paddingTop: "0.75rem", // Adjusted padding for vertical centering
                  paddingBottom: "0.75rem", // Ensures equal padding on top and bottom
                  lineHeight: "1.25rem", // Line height to help with vertical centering
                  boxSizing: "border-box", // Includes padding and border in the element's total width and height
                }}
                value={inputs.zipCode}
                onChange={handleInputChange}
                placeholder=" " // Placeholder should be empty to prevent extra space
              />
              {errors.zipCode && (
                <p
                  className="text-red-500 text-left text-xs absolute"
                  style={{ left: "10px", bottom: "-1.5rem" }} // Adjust positioning for error message
                >
                  {errors.zipCode}
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-between items-center mb-2">
            <div className="flex justify-center items-center w-full mb-4">
              <button
                type="button"
                onClick={handleCancel}
                className="font-poppins text-base font-medium leading-5 tracking-tighter text-center"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  letterSpacing: "-0.1px",
                  textAlign: "center",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4 w-full">
            <div
              onClick={handleSave}
              className={`poppins-bold h-[51px] p-4 w-full mx-4 text-white text-center rounded-md shadow-md cursor-pointer font-poppins ${
                !isFormFilled ? "blur-bg" : "default-bg"
              }`}
               style= {layout?.buttonStyle }
            >
              {address ? "UPDATE" : "SAVE"}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BillingAddressScreen;
