// src/components/AddPaymentMethod.js

import  { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import { useAsaSession } from '../../services/AsaSessionServices';
import { useFintechLayouts } from '../../services/AsaApiServices';
import "./SubscriptionManagement.css"; 

const SubscriptionAddPaymentMethod = () => {
  const layout = useFintechLayouts();
  const { data: session } = useAsaSession();
  const navigate = useNavigate();
  const [dynamicLink,setdynamicLink]=useState<string | undefined>()

  useEffect(()=>{
    const linkAccountDynamicLink = session?.sessionPages?.find(page => page.pageNumber === 0)?.pageData
    setdynamicLink(linkAccountDynamicLink)
  },[session])

  const handleLinkAccountClick = () => {
    if (!session?.sessionPages) {
      console.error("UpdatedLocalData is not available.");
      return;
    }


    if (dynamicLink) {
      window.location.href = dynamicLink;
    } else {
      console.error("Back action dynamic link not found.");
    }
  };

  const handleBackClick = () => navigate(-1);
  const handleManualAddClick = () => navigate("/addcreditcard");

  return (
    <div
      className="bg-gradient-to-b from-[#705AE2] to-[#490C95] h-screen flex flex-col items-center text-center pt-10 relative"
      style={layout?.backGroundStyle}
    >
      <div className="absolute top-4 left-4 rounded-lg p-1 w-[40px] h-[40px] flex items-center justify-center">
        <img
          className="cursor-pointer w-[18px] h-[18px] mt-8"
          src={arrow_back_ios_new}
          alt="Back"
          onClick={handleBackClick}
        />
      </div>
      <h1 className="text-white font-bold text-xl">Add a Payment Method</h1>
      <div className="bg-[#121212] rounded-t-3xl shadow-md mt-10 w-full max-w-md p-6 flex-grow flex flex-col">
        <PaymentButton
          onClick={handleLinkAccountClick}
          style={layout?.buttonStyle}
          title="Link an account"
          subtitle="Updated automatically"
          disabled={!!!dynamicLink}
        />
        <PaymentDescription />
        <DividerWithText text="or" />
        <ManualAddButton onClick={handleManualAddClick} />
      </div>
    </div>
  );
};

const PaymentButton = ({ onClick, style, title, subtitle,disabled }
                      :{onClick:()=>void,style:any,title:string,subtitle:string,disabled:boolean}
) => (
  <button
    className="bg-[#705AE2] text-white box-style-add-payments py-3 px-8 rounded-full shadow-md mb-4 mx-auto w-full max-w-[294px] h-[56px] flex flex-col justify-center items-center"
    onClick={onClick}
    style={{...style,...(disabled?{backgroundColor:"dimgray",opacity:0.5}:{})}}
    disabled={disabled}
  >
    <span className="font-poppins font-medium text-[15px] leading-[20px] text-center">
      {title}
    </span>
    <span className="font-poppins text-[10px] leading-[20px] text-center">
      {subtitle}
    </span>
  </button>
);

const PaymentDescription = () => (
  <p className="text-[#FFFFFF] mt-2 leading-4">
    <span className="button-styles-p-port font-poppins">
      Connect to more than 10,000
    </span>
    <br />
    <span className="font-normal button-styles-p-span-meth font-poppins">
      bank accounts, credit cards,
    </span>
    <br />
    <span className="font-normal button-styles-p-span-meth font-poppins">
      investment accounts, and more
    </span>
  </p>
);

const DividerWithText = ({ text }:{text:string}) => (
  <div className="flex items-center my-10">
    <hr className="w-full border-[#4E4E4E]" />
    <span className="px-2 text-gray-500">{text}</span>
    <hr className="w-full border-[#4E4E4E]" />
  </div>
);

const ManualAddButton = ({ onClick }:{onClick:()=>void}) => (
  <>
    <span className="manual-text-port font-poppins">
      Add a payment method manually
    </span>
    <button
      onClick={onClick}
      className="my-6 font-poppins bg-white border-gray-300 text-gray-700 font-semibold rounded-full py-4 px-6 w-full shadow-md hover:bg-gray-100"
    >
      Manually Add
    </button>
  </>
);

export default SubscriptionAddPaymentMethod;