import React from "react";
import { useFintechLayouts} from "../services/AsaApiServices"
const LoaderComponent = () => {

const layout=useFintechLayouts()
  return (
    <div className={`loader-container ${layout?'':'loader-container-init'}`}>
      <div className="loader"></div>
    </div>
  );
};
export default LoaderComponent;
