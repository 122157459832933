import  {  useState  } from "react";

import ImageTextSlider from "../../components/pager";
import arrowBack from "../../assets/Images/arrow_back_ios_new.png";
import flash from "../../assets/Images/flash_white.png";
import like from "../../assets/Images/like.png";
import building from "../../assets/Images/apap.png";
import refresh from "../../assets/Images/refresh.png";
import question from "../../assets/Images/question.png";
import contract from "../../assets/Images/contract.png";
import forward_arrow from "../../assets/Images/forward_arrow.png";
import {useAsaSession,useIsLoading,useAsaSessionPagesMutation} from '../../services/AsaSessionServices'
import {  useActiveSubscriptions,useSubscriptionsPlans} from '../../services/AsaApiServices'
import { IAsaSession, SessionPage,Plan } from "../../types/types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

import LoaderComponent from "../../components/LoaderComponent";

const GoalSubscription = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useIsLoading();
  
  const {data:session}=useAsaSession()
  const {data:subscriptions}=useActiveSubscriptions()
  const {data:subscriptionsPlans}=useSubscriptionsPlans()
  const sessionMutation= useAsaSessionPagesMutation()
  const [showPlans, setshowPlans]=useState(false)
  const updateSessiongoal = async (
    data: any,
    pageNumber: number,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {


      const sessionToUpdate:IAsaSession ={...session} as IAsaSession
      sessionToUpdate.sessionPages=undefined
      const sessionpages=[] as SessionPage[]
      sessionpages.push({
        pageNumber:pageNumber,
        pageName:pageName,
        pageTitle:pageTitle,
        pageDescription:pageDescription,
        pageUrl:pageUrl,
        pageData: JSON.stringify(data)
      } as SessionPage)
    try {

      const response= await sessionMutation.mutateAsync({asaSession:sessionToUpdate,extraPages:sessionpages})
      console.log(response)
      return response;
    } catch (error) {
     
      
      console.error("Error updating session:", error);
      
    }
  };
  // Handling Update Session response

  const handlePlanClick = async (plan: Plan) => {
    if (subscriptions?.some(()=>true)) {
      navigate("/manageSubscriptions");
    }else{
      await updateSessiongoal(
        plan,
        2,
        "GOALS PLANS",
        "Select Package",
        "Select Package",
        "/guac",
        "Completed"
      );
      navigate("/subscriptionpayment");
    }

  }
  const handleBackClick = () => {

    const targetPage = session?.sessionPages?.find(page => page.pageNumber === 0);
    if (targetPage) {
      const pageData = JSON.parse(targetPage.pageData);
      const backActionDynamicLink = pageData.backActionDynamicLink;
      if (backActionDynamicLink) {
        window.location.href = backActionDynamicLink;
      } else {
        console.error("Back action dynamic link not found.");
      }
    }

  };

  const slides = [
    {
      image: building,
      text: "See all your accounts in one place!",
    },
    {
      image: refresh,
      text: "Account balances sync with your bank automatically",
    },
    {
      image: contract,
      text: "Transactions are automatically sent to One Goal!",
    },
    {
      image: like,
      text: "Drag and drop to categorize transactions",
    },
    {
      image: question,
      text: "Get Priority Support",
    },
  ];

  const clickSubscription = () => {
    setIsLoading(true);

    //  console.log("Updated Local data ::: " + UpdatedLocalData);
    setTimeout(() => {
      setIsLoading(false);
      setshowPlans(true);
    }, 2000); // 5000 milliseconds = 5 seconds


  };
  
  return (
    <div className="bg-gray-900 flex justify-center items-center min-h-screen">
      {isLoading && <LoaderComponent />}
      <div className="bg-gradient-to-r from-[#1B9C83] to-[#20BD9D] w-full h-screen overflow-y-auto text-white relative z-20">
        <div className="flex items-center justify-evenly space-x-8 pt-8 p-3">
          <div className="rounded-[10px] p-1 w-[70px] h-[45px] styled-box-goal flex items-center justify-center">
            <img
              className="cursor-pointer w-[20px] h-[20px]"
              src={arrowBack}
              onClick={handleBackClick}
              alt="Back"
            />
          </div>

          <h1 className="font-poppins ml-3  text-[22px] leading-[28px] tracking-[1px] w-[500px] h-[56px] mt-6 ">
            Love & Money, Finally
            <br /> in Sync.
          </h1>

          {/* <img className="h-16 w-16 mx-6" src={pwa_image} alt="PWA" /> */}
        </div>

        <img
          src={flash}
          className="relative top-2 left-5 z-50 w-[65px] h-[65px]"
          alt="Flash"
        />

        <div className="bg-[#121212] top-8 rounded-more text-gray-800 mx-auto rounded-t-[3rem] absolute bottom-0 left-0 right-0 h-full overflow-y-auto mt-24">
          <div className="h-full p-6 shadow-lg">
            <div className="flex items-center mb-2">
              <img
                src={flash}
                className="relative -top-2 hidden left-5 z-50 w-[80px] h-[80px]"
                alt="Flash"
              />
              <span className="font-semibold relative -top-4 font-poppins left-20 text-[18px] text-white">
                One Goal
              </span>
            </div>

            <div className="border-[3.91px] border-[#1B9C83] rounded-xl">
              <div className="rounded-xl bg-[#c9eae5] p">
                <button className="bg-[#39a68f] border-[#1B9C83] button-one-goal border text-black relative top-2 left-3 px-2 py-1 tracking-widest rounded-full border-b-4 text-xs mb-6">
                  SUBSCRIBE
                </button>

                <ImageTextSlider slides={slides} />
              </div>
              <div className="bg-white">
                <div className="space-y-3 mt-6 p-2 bg-white rounded-xl">
                  {!showPlans ? (
                    <button
                      onClick={clickSubscription}
                      className="w-full bg-[#39a68f] subscription-goal text-white py-5 rounded-lg shadow-md font-poppins font-semibold flex justify-between items-center"
                    >
                      <span className="ml-4 font-poppins">Continue</span>{" "}
                      {/* Left margin for Continue text */}
                      <img
                        src={forward_arrow}
                        alt="Forward Arrow"
                        className="w-2 h-4 mr-4"
                      />{" "}
                      {/* Right margin for arrow */}
                    </button>
                  ) : (
                    <>
                      {subscriptionsPlans && subscriptionsPlans.length > 0 ? ( // Check if plans is not null and has items
                        subscriptionsPlans.map((plan) => (
                          <button
                            key={plan.subscriptionPlanCode}
                            onClick={() => handlePlanClick(plan)} // Ensure handlePlanClick is defined
                            className={`w-full text-white p-4 rounded-xl text-left flex justify-between items-center ${
                              subscriptions?.some((x)=>x.subscriptionPlanCode===plan.subscriptionPlanCode) 
                                ? "bg-gradient-to-r from-[#5BCCB3] to-[#3aa690]"
                                : "bg-gradient-to-r from-[#1B9C83] to-[#3aa690]"
                            }`}
                          >
                            { !subscriptions?.some((x)=>x.subscriptionPlanCode===plan.subscriptionPlanCode) &&
                            <div>
                              <div className="font-poppins text-style-one-goal">
                                {plan.billingCycle === "Monthly"
                                  ? `$${plan.recurringAmount} / month`
                                  : `$${plan.recurringAmount} / year`}
                              </div>
                              <div className="font-medium font-poppins small-text-one-goal">
                                Billed{" "}
                                {plan.billingCycle === "Monthly"
                                  ? plan.recurringAmount
                                  : plan.oneTimeAmount}{" "}
                                every{" "}
                                {plan.billingCycle.toLowerCase() === "annually"
                                  ? "year"
                                  : "month"}
                                , starting today
                              </div>
                            </div>
                            }
                            { subscriptions?.some((x)=>x.subscriptionPlanCode===plan.subscriptionPlanCode) &&
                            <div>
                              <div className="font-poppins text-style-one-goal">
                                {plan.billingCycle === "Monthly"
                                  ? `$${plan.recurringAmount} / month`
                                  : `$${plan.recurringAmount} / year`}
                              </div>
                              <div className="font-medium font-poppins small-text-one-goal">
                                   Manage
                              </div>
                            </div>
                            }
                            <div className="w-2 h-2">
                              <img src={forward_arrow} alt="Forward Arrow" />
                            </div>
                          </button>
                        ))
                      ) : (
                        <p className="no-plans-message font-poppins">
                          No subscription plans available
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="bg-[#121212] mt-6 px-6">
              <p className="continiuep text-left font text-white text-[13px] font-medium paragraph-one-goal">
                By continuing, you are indicating that you accept our{" "}
                <a
                  href="https://onegoalfinance.com/terms"
                  target="_blank"
                  className="underline font-poppins font-semibold text-[13px]"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://onegoalfinance.com/privacy-policy"
                  target="_blank"
                  className="underline font-poppins font-semibold text-[13px]"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>

            {/* Enhanced Popup Modal */}

          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalSubscription;
