import axios, {AxiosError} from "axios";

import { env } from '../Utils/env'
import {UseQueryResult, QueryClient,  useQuery, useQueryClient,useMutation,UseMutationResult } from "react-query";
import  {  useEffect,useContext } from "react";
import {IAsaResponse, setBoolFn,IAsaSessionState,validateFn,PaymentMethod,IFeedback,
  BillingAddress,IAsaSession, SessionPage,IRateOnUpgradePlan,
  SubscriptionDetail,Plan,Fintech,IFintechLayout,IFintechAppLayoutModel} from '../types/types'
import { AsaStateContext } from '../components/AsaStateProvider'
import { API_URLS, QUERY_KEYS } from "./AsaApiConstants";



const getAsaHeaders = (state:IAsaSessionState) => {
    const headers= {
      Accept: "application/json",
      "Content-Type": "application/json",
       asaConsumerCode: state.asaConsumerCode,
      X_ASA_version: state.xAsaVersion,
      "Ocp-Apim-Subscription-Key": state.subscriptionKey || env.REACT_APP_SUBSCRIPTION,
      AsaFintechCode: state.asaFintechcode,
      FintechApplicationCode:state.appCode,
      requestid:`PWA-${new Date().getTime()}`,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      
    };
    
    if(state.token && state.token.length>0) 
      headers.Authorization= 'Bearer ' + state.token
    //console.log('getAsaHeaders headers',headers)
    return headers
  };
  async function callApiGet<TData>  (url:string,state:IAsaSessionState ,checkstatusCode:boolean=true,extraHeaders:any=undefined ){
   
    const headers=  getAsaHeaders(state);
       const {data} = await axios.get<IAsaResponse<TData>>(
            url,
        {
            headers  :{...headers,...extraHeaders},
            withCredentials: true
        }
    )
    if(checkstatusCode && data.status!==200 )
         throw new Error(data.message);
    return data.data;
  }
  async function callApiPost<TData>  (url:string,state:IAsaSessionState ,body:any, checkstatusCode:boolean=true,extraHeaders:any=undefined ):Promise<TData>{
   
    const headers=  getAsaHeaders(state);
    
    const {data} = await axios.post<IAsaResponse<TData>>(
        url,body,
        {
            headers  :{...headers,...extraHeaders},
            withCredentials: true 
        }
    )
    
    if(checkstatusCode && data.status!==200 )
         throw new Error(data.message);
    return data.data;
  }
  async function callApiDelete<TData>  (url:string,state:IAsaSessionState ,body:any, checkstatusCode:boolean=true ):Promise<TData>{
   
    const headers=  getAsaHeaders(state);
    const {data} = await axios.delete<IAsaResponse<TData>>(
        url,
        {
            headers  :{...headers},
            data:body
        }
    )
    
    if(checkstatusCode && data.status!==200 )
         throw new Error(data.message);
    return data.data;
  }
  async function callApiPut<TData>  (url:string,state:IAsaSessionState ,body:any, checkstatusCode:boolean=true ):Promise<TData>{
   
    const headers=  getAsaHeaders(state);
    const {data} = await axios.put<IAsaResponse<TData>>(
        url,body,
        {
            headers  :{...headers},
        }
    )
    
    if(checkstatusCode && data.status!==200 )
         throw new Error(data.message);
    return data.data;
  }

  function useAsaQuery<TData>(keys:any,
        query:any,
        setLoading:setBoolFn | undefined=undefined,
        setError:setBoolFn | undefined=undefined,
        validate:validateFn =(v)=>true
      ):UseQueryResult<TData, any>{
    const [state, ] = useContext(AsaStateContext)
    const isAuthorized=!!state.token
    const queryfn= (typeof query =='string')?()=>callApiGet(query,state):query
    
    const reactquery= useQuery<TData,AxiosError>({
        queryKey: keys,
        queryFn: async () => {
            if(validate(query) && isAuthorized)
              return await queryfn();
            return null;
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false,
        staleTime: 100000,
        enabled:isAuthorized
        
      });
    
  
  
    useEffect(() => {
      setLoading && setLoading(reactquery.isFetching)
      setError && setError(reactquery.isError)
    },[reactquery.isError,reactquery.isFetching,setError,setLoading]);
    if(reactquery.isError){
        console.log(reactquery.error)
    }
    return reactquery
  }

  function useActiveSubscriptions(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
    :UseQueryResult<SubscriptionDetail[], any>{
    const [state, ] = useContext(AsaStateContext)
  
    const res=useAsaQuery<SubscriptionDetail[]>([QUERY_KEYS.ACTIVE_SUBSCRIPTIONS,state.asaConsumerCode,state.asaFintechcode],
      env.REACT_APP_API_URL + `Consumer/ActiveSubscriptions`
      ,setLoading,setError,
      (v)=>!!(state.asaConsumerCode && state.asaFintechcode)
    )
    
    return res;
    
  }
  //for backward compatibility, who not used mutation
  function useInvalidateActiveSubscription():()=>void{
    const  [state, ] = useContext(AsaStateContext)
    const queryclient =useQueryClient()
    const fn=()=>{
      queryclient.invalidateQueries([QUERY_KEYS.ACTIVE_SUBSCRIPTIONS,state.asaConsumerCode,state.asaFintechcode])
    }
    return fn;
  }
  function useFintechSubscriptions(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
  :UseQueryResult<SubscriptionDetail[], any>{
  const [state, ] = useContext(AsaStateContext)

  const res=useAsaQuery<SubscriptionDetail[]>([QUERY_KEYS.FINTECH_SUBSCRIPTIONS,state.asaConsumerCode,state.asaFintechcode],
    env.REACT_APP_API_URL + `Consumer/FintechSubscriptions`
    ,setLoading,setError,
    (v)=>!!(state.asaConsumerCode && state.asaFintechcode)
  )
  
  return res;
  
}
export const saveSubscriptionPurchase = async (asaState:IAsaSessionState,body:any)=>{

  const response = await callApiPost(
    env.REACT_APP_API_URL + `subscriptions/PurchaseFreePlan`,
    asaState,
    body
  );
  return response
} 
export const saveBillingAddress = async (asaState:IAsaSessionState,body:any)=>{

  const response = await callApiPost(
    env.REACT_APP_API_URL + `Consumer/PostConsumerAddress`,
    asaState,
    body
  );
  return response
} 
export const saveSubscriptionPayment = async (asaState:IAsaSessionState,body:any)=>{

  const response = await callApiPost(
    env.REACT_APP_API_URL + `payment/payment`,
    asaState,
    body
  );
  return response
} 
export const saveSubscriptionPaymentMethod = async (asaState:IAsaSessionState,body:any)=>{

  const response = await callApiPost(
    env.REACT_APP_API_URL + `subscriptions/UpdatePaymentMethod`,
    asaState,
    body
  );
  return response
} 
export const savePaymentMethod = async (asaState:IAsaSessionState,body:any)=>{

  const response = await callApiPost(
    env.REACT_APP_API_URL + `Payment/PaymentMethod`,
    asaState,
    body
  );
  return response
} 
export const deletePaymentMethod = async (asaState:IAsaSessionState,body:any)=>{

  const response = await callApiDelete(
    env.REACT_APP_API_URL + `Payment/PaymentMethod`,
    asaState,
    body
  );
  return response
} 
export const cancelSubscription = async (asaState:IAsaSessionState,body:any)=>{

  const response = await callApiPost(
    env.REACT_APP_API_URL + `Consumer/CancelSubscription`,
    asaState,
    body
  );
  return response
} 
function useSubscriptionPurchaseMutation( ):UseMutationResult<any, any, any, any>{
  const  [state, ] = useContext(AsaStateContext)
  const queryclient =useQueryClient()
  const queryKeyall=[QUERY_KEYS.FINTECH_SUBSCRIPTIONS,state.asaConsumerCode,state.asaFintechcode]
  const queryKeyactive=[QUERY_KEYS.ACTIVE_SUBSCRIPTIONS,state.asaConsumerCode,state.asaFintechcode]
  const mutation=useMutation<any,any,any,any>({
    mutationFn: (args ) =>
      saveSubscriptionPurchase(state, args),
      //TO do validate backend, potentially queryclient.setquerydata(.....)
      onSuccess:() =>{
        queryclient.invalidateQueries({ queryKey:queryKeyall})
        queryclient.refetchQueries({queryKey:queryKeyall})
        queryclient.invalidateQueries({ queryKey:queryKeyactive})
        queryclient.refetchQueries({queryKey:queryKeyactive})
      }
    })
  return mutation;
}
function useSubscriptionPaymentMutation( ):UseMutationResult<any, any, any, any>{
  const  [state, ] = useContext(AsaStateContext)
  const queryclient =useQueryClient()
  const queryKeyall=[QUERY_KEYS.FINTECH_SUBSCRIPTIONS,state.asaConsumerCode,state.asaFintechcode]
  const queryKeyactive=[QUERY_KEYS.ACTIVE_SUBSCRIPTIONS,state.asaConsumerCode,state.asaFintechcode]
  const mutation=useMutation<any,any,any,any>({
    mutationFn: (args ) =>
      saveSubscriptionPayment(state, args),
      //TO do validate backend, potentially queryclient.setquerydata(.....)
      onSuccess:() =>{
        queryclient.invalidateQueries({ queryKey:queryKeyall})
        queryclient.refetchQueries({queryKey:queryKeyall})
        queryclient.invalidateQueries({ queryKey:queryKeyactive})
        queryclient.refetchQueries({queryKey:queryKeyactive})
      }
    })
  return mutation;
}
function useSubscriptionPaymentMethodUpdateMutation( ):UseMutationResult<any, any, any, any>{
  const  [state, ] = useContext(AsaStateContext)
  const queryclient =useQueryClient()
  const queryKeyall=[QUERY_KEYS.FINTECH_SUBSCRIPTIONS,state.asaConsumerCode,state.asaFintechcode]
  const queryKeyactive=[QUERY_KEYS.ACTIVE_SUBSCRIPTIONS,state.asaConsumerCode,state.asaFintechcode]
  const mutation=useMutation<any,any,any,any>({
    mutationFn: (args ) =>
      saveSubscriptionPaymentMethod(state, args),
      //TO do validate backend, potentially queryclient.setquerydata(.....)
      onSuccess:() =>{
        queryclient.invalidateQueries({ queryKey:queryKeyall})
        queryclient.refetchQueries({queryKey:queryKeyall})
        queryclient.invalidateQueries({ queryKey:queryKeyactive})
        queryclient.refetchQueries({queryKey:queryKeyactive})
      }
    })
  return mutation;
}
function useSubscriptionCancelMutation( ):UseMutationResult<any, any, any, any>{
  const  [state, ] = useContext(AsaStateContext)
  const queryclient =useQueryClient()
  const queryKeyall=[QUERY_KEYS.FINTECH_SUBSCRIPTIONS,state.asaConsumerCode,state.asaFintechcode]
  const queryKeyactive=[QUERY_KEYS.ACTIVE_SUBSCRIPTIONS,state.asaConsumerCode,state.asaFintechcode]
  const mutation=useMutation<any,any,any,any>({
    mutationFn: (args ) =>
      cancelSubscription(state, args),
      //TO do validate backend, potentially queryclient.setquerydata(.....)
      onSuccess:() =>{
        queryclient.invalidateQueries({ queryKey:queryKeyall})
        queryclient.refetchQueries({queryKey:queryKeyall})
        queryclient.invalidateQueries({ queryKey:queryKeyactive})
        queryclient.refetchQueries({queryKey:queryKeyactive})
      }
    })
  return mutation;
}

function useSubscriptionsPlans(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
:UseQueryResult<Plan[], AxiosError>{
const [state, ] = useContext(AsaStateContext)

const res=useAsaQuery<Plan[]>([QUERY_KEYS.SUBSCRIPTION_PLANS,state.asaConsumerCode,state.asaFintechcode],
  env.REACT_APP_API_URL + `Fintech/SubscriptionPlans`
  ,setLoading,setError,
  (v)=>!!(state.asaConsumerCode && state.asaFintechcode)
)

return res;

}

function useBillingAddresses(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
:UseQueryResult<BillingAddress[], AxiosError>{
const [state, ] = useContext(AsaStateContext)

const callApi=async()=>{ 
   const addresses= await callApiGet<BillingAddress[]>(`${ env.REACT_APP_API_URL}Consumer/GetConsumerAddress`,state)
   return addresses.filter(x=>x.isBilling === true)
}
const res=useAsaQuery<BillingAddress[]>([QUERY_KEYS.BILLING_ADDRESSES,state.asaConsumerCode,state.asaFintechcode],
  callApi
  ,setLoading,setError,
  (v)=>!!(state.asaConsumerCode && state.asaFintechcode)
)
return res;
}
function useRateOnUpgradePlan(prevPlan:number | undefined,newPlan:number | undefined):UseQueryResult<IRateOnUpgradePlan, AxiosError>{
  const [state, ] = useContext(AsaStateContext)
  const body={subscriptionPlanCode:newPlan,prevSubscriptionPlanCode:prevPlan}
  const callApi=()=>{
    if(prevPlan && newPlan && prevPlan!==newPlan){
      callApiPost<IRateOnUpgradePlan>(`${ env.REACT_APP_API_URL}Subscriptions/RateOnUpgradePlan`,state,body)
    }else{
      return null
    }
  }
  const res=useAsaQuery<IRateOnUpgradePlan>(['RateOnUpgradePlan',state.asaConsumerCode,state.asaFintechcode,prevPlan,newPlan],
    callApi)
  return res;
}

function useBillingAddresseslMutation( ):UseMutationResult<any, any, any, any>{
  const  [state, ] = useContext(AsaStateContext)
  const queryclient =useQueryClient()
  const queryKey=['BillingAddresses',state.asaConsumerCode,state.asaFintechcode]
 
  const mutation=useMutation<any,any,any,any>({
    mutationFn: (args ) =>
      saveBillingAddress(state, args),
    
      onSuccess:() =>{
        queryclient.invalidateQueries({ queryKey:queryKey})
        queryclient.refetchQueries({queryKey:queryKey})

      }
    })
  return mutation;
}
//Fedbacks
  function useFeedbacks(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
    :UseQueryResult<IFeedback[], AxiosError>{
    const [state, ] = useContext(AsaStateContext)
  
    const res=useAsaQuery<IFeedback[]>([QUERY_KEYS.FEEDBACKS,state.asaConsumerCode,state.asaFintechcode],
      env.REACT_APP_API_URL + `Feedback`
      ,setLoading,setError,
      (v)=>!!(state.asaConsumerCode && state.asaFintechcode)
    )
    
    return res;
    
  }
  export const saveFeedback = async (asaState:IAsaSessionState,rate:number,msg:string,subScriptionPlanCode:number|undefined)=>{
    const saveBody:IFeedback={
      asaConsumerCode:asaState.asaConsumerCode,
      asaFintechCode:asaState.asaFintechcode,
      rating:rate,
      feedbackMessage:msg,
      subscriptionPlanCode:subScriptionPlanCode
    } as IFeedback
  
    const response = await callApiPost(
      env.REACT_APP_API_URL + `Feedback`,
      asaState,
      saveBody
    );
    return response
  }

  function useFeedbackMutation( ):UseMutationResult<any, any, any, any>{
    const  [state, ] = useContext(AsaStateContext)
    const queryclient =useQueryClient()
    const queryKey=['Feedbacks',state.asaConsumerCode,state.asaFintechcode]
    const mutation=useMutation<any,any,any,any>({
      mutationFn: (args ) =>
        saveFeedback(state,args.rate,args.msg,args.subScriptionPlanCode),
        //TO do validate backend, potentially queryclient.setquerydata(.....)
        onSuccess:() =>{
          queryclient.invalidateQueries({ queryKey:queryKey})
          queryclient.refetchQueries({queryKey:queryKey})
        }
      })
    return mutation;
  }
// payment methods

function usePaymentMethods(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
:UseQueryResult<PaymentMethod[], any>{
const [state, ] = useContext(AsaStateContext)

const res=useAsaQuery<PaymentMethod[]>([QUERY_KEYS.PAYMENT_METHODS,state.asaConsumerCode,state.asaFintechcode],
  env.REACT_APP_API_URL + `Payment/PaymentMethod`
  ,setLoading,setError,
  (v)=>!!(state.asaConsumerCode && state.asaFintechcode)
)

return res;

}
function usePaymentMethodsMutation( ):UseMutationResult<any, any, any, any>{
  const  [state, ] = useContext(AsaStateContext)
  const queryclient =useQueryClient()
  const queryKey=[QUERY_KEYS.PAYMENT_METHODS,state.asaConsumerCode,state.asaFintechcode]
 
  const mutation=useMutation<any,any,any,any>({
    mutationFn: (args ) =>
      savePaymentMethod(state, args),
    
      onSuccess:() =>{
        queryclient.invalidateQueries({ queryKey:queryKey})
        queryclient.refetchQueries({queryKey:queryKey})

      }
    })
  return mutation;
}
function usePaymentMethodsDeleteMutation( ):UseMutationResult<any, any, any, any>{
  const  [state, ] = useContext(AsaStateContext)
  const queryclient =useQueryClient()
  const queryKey=[QUERY_KEYS.PAYMENT_METHODS,state.asaConsumerCode,state.asaFintechcode]
 
  const mutation=useMutation<any,any,any,any>({
    mutationFn: (args ) =>
      deletePaymentMethod(state, args),
    
      onSuccess:() =>{
        queryclient.invalidateQueries({ queryKey:queryKey})
        queryclient.refetchQueries({queryKey:queryKey})

      }
    })
  return mutation;
}

function useFintech(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
  :UseQueryResult<Fintech[], any>{
  const [state, ] = useContext(AsaStateContext)

  const res=useAsaQuery<Fintech[]>([QUERY_KEYS.FINTECH,state.asaConsumerCode,state.asaFintechcode],
    env.REACT_APP_API_URL + `Fintech/All`
    ,setLoading,setError,
    (v)=>!!(state.asaConsumerCode && state.asaFintechcode)
  )
  return res;

}
function prefetchFintechLayout(state:IAsaSessionState, queryClient:QueryClient ){
  
  if(state.asaFintechcode 
    && (state.appCode || state.xAsaVersion<1.09)
    && ( state.subscriptionKey ||  env.REACT_APP_SUBSCRIPTION)
    ){
      console.log("prefetchFintechLayout")
      queryClient.prefetchQuery({
        queryKey:['FintechAppLayout',state.asaFintechcode,state.appCode],
        queryFn: ()=>{ callApiGet(env.REACT_APP_API_URL + `Fintech/FintechAppLayout`,state)},
        // Prefetch only fires when data is older than the staleTime,
        // so in a case like this you definitely want to set one
        staleTime: 60000,
      })
  }
}
function useFintechAppLayout(setLoading:setBoolFn | undefined=undefined, setError:setBoolFn | undefined=undefined)
  :UseQueryResult<IFintechAppLayoutModel, any>{
  const [state, ] = useContext(AsaStateContext)
 
  const res=useAsaQuery<IFintechAppLayoutModel>([QUERY_KEYS.FINTECH_APP_LAYOUT,state.asaFintechcode,state.appCode],
    env.REACT_APP_API_URL + `Fintech/FintechAppLayout`
    ,setLoading,setError,
    (v)=>!!(state.asaFintechcode && (state.appCode || state.xAsaVersion<1.09))
  )
  
  return res;

}
const translateGradientDir=(dir:string)=>{
  switch(dir){
    case "Top_Bottom":
      return "180deg"
    case "Bottom_Top":
        return "0deg"
    case "Left_Right":
          return "270deg"
    default:
      return "90deg"
  }
}
const translateGradientStyle=(dir:string,startColor:string,endcolor:string)=>{
  switch(dir){
    case "Top_Bottom":
      return `repeating-linear-gradient(180deg,
          ${startColor},
          ${endcolor} 25%) `
    case "Bottom_Top":
      return `repeating-linear-gradient(180deg,
      ${endcolor},
      ${startColor} 25%)`
    case "Left_Right":
          return `linear-gradient(270deg,
          ${startColor},
          ${endcolor})`
    default:
      return `linear-gradient(90deg,
          ${startColor},
          ${endcolor})`
  }
}
function getLayoutFromFintech(fintech:Fintech | Fintech[] | undefined):IFintechLayout | null{
  if(!fintech || (Array.isArray(fintech) && fintech.length===0) ) return null
  const ft=Array.isArray(fintech)?fintech[0]:fintech

  return  {
    appName:ft.applicationName,
    icon:ft.fintechIcon,
    backGroundStyleFullScreen:{background: `linear-gradient(${translateGradientDir(ft.gradientDirection)},
    ${ft.gradientColorStart},
    ${ft.gradientColorEnd})` },
    backGroundStyle:{background: translateGradientStyle(ft.gradientDirection,ft.gradientColorStart,ft.gradientColorEnd)},

    buttonStyle:{background:`${ft.buttonColor}`},
    gradientColorStart:ft.gradientColorStart,
    gradientColorEnd:ft.gradientColorEnd,
    fintechName:ft.fintechName

  }
}
function getLayoutFromFintechApp(ft: IFintechAppLayoutModel| undefined):IFintechLayout | null{
  if(!!!ft){
    return null
  }
 
  const gradStart=(ft?.applicationGradientColorStart)?? ft?.fintechApplicationgradientColorStart
  const gradEnd=(ft?.applicationGradientColorEnd)?? ft?.fintechApplicationgradientColorEnd
  const gradDir= (ft?.applicationGradientDirection)?? ft?.fintechGradientDirection
  const btnColor= (ft?.applicationButtonColor)?? ft?.fintechButtonColor
  
  return  {
    appName:ft?.applicationName,
    icon:(ft?.applicationIcon)?? ft?.fintechIcon,
    backGroundStyleFullScreen:{background: `linear-gradient(${translateGradientDir(gradStart)},
    ${gradStart},
    ${gradEnd})` },
    backGroundStyle:{background: translateGradientStyle(gradDir,gradStart,gradEnd)},

    buttonStyle:{background:`${btnColor}`},
    gradientColorStart:gradStart,
    gradientColorEnd:gradEnd,
    fintechName:ft?.fintechName

  } as IFintechLayout
}
function useFintechLayouts():IFintechLayout | null{
  //const {data:fintechs}=useFintech()
  //return getLayoutFromFintech(fintechs)
  const{data:ftapp}=useFintechAppLayout()
  return getLayoutFromFintechApp(ftapp)
}
// function to keep backward compatbility to fill session pages, needed to check out screen (/ListPay)
const getCheckOutSessionPages=async (state:IAsaSessionState)
:Promise<{pages:SessionPage[],billingAddresses:unknown,subscriptionsPlans:Plan[] | undefined}>=>{
  try{
      const pages:SessionPage[]=[]
      const paymethods=await callApiGet(`${env.REACT_APP_API_URL}Payment/PaymentMethod`,state) 

      pages.push({
          pageNumber:3,
          pageName:'PaymentMethodList',
          pageTitle:'PaymentMethodList',
          pageDescription:'PaymentMethodList',
          pageUrl:'/PaymentList',
          pageData:  JSON.stringify(paymethods)
      } as SessionPage)
      const billingAddresses=await callApiGet(`${ env.REACT_APP_API_URL}Consumer/GetConsumerAddress`,state) 
      pages.push({
        pageNumber:7,
        pageName:'BillAddressList',
        pageTitle:'BillAddressList',
        pageDescription:'BillAddressList',
        pageUrl:'/onegoal',
        pageData:  JSON.stringify(billingAddresses)
    } as SessionPage)

     const subscriptionsPlans=await callApiGet<Plan[]>(`${ env.REACT_APP_API_URL}Fintech/SubscriptionPlans`,state) 
     /*
     pages.push({
        pageNumber:1,
        pageName:'GOALS PLANS',
        pageTitle:'GOALS PLANS',
        pageDescription:'USER SELECTED PLAN',
        pageUrl:'/onegoal',
        pageData: JSON.stringify(subscriptionsPlans)
      } as SessionPage)
     */
      const fintech = await callApiGet<Fintech[]>(`${ env.REACT_APP_API_URL}Fintech/All`,state) 
      const filtered_data = fintech.filter(
        (item: any) => item["asaFintechCode"] === state.asaFintechcode
      );

      pages.push({
        pageNumber:91,
        pageTitle:'FintechName',
        pageName:'FintechName',
        pageDescription:'FintechName',
        pageUrl:'/FintechName',
        pageData:  JSON.stringify(filtered_data)//JSON.stringify(fintech.map((item: any) => item["fintechName"]))
      } as SessionPage)
      return {pages:pages,billingAddresses:billingAddresses,subscriptionsPlans:subscriptionsPlans};
  }
  catch(err){
    console.log(err)
    return {pages:[],billingAddresses:undefined,subscriptionsPlans:undefined};
  }
}

  export {useAsaQuery,callApiGet,callApiPost,
    useActiveSubscriptions,useFeedbacks,useFeedbackMutation,useFintechSubscriptions,
    useBillingAddresses,useSubscriptionsPlans,useSubscriptionPurchaseMutation,usePaymentMethods,
    callApiPut,getCheckOutSessionPages,useFintech ,useFintechLayouts,
    getLayoutFromFintech,useInvalidateActiveSubscription,useSubscriptionCancelMutation as useSubscriptionCancedlMutation,
    useSubscriptionPaymentMutation,useBillingAddresseslMutation,
    useSubscriptionPaymentMethodUpdateMutation,usePaymentMethodsMutation,usePaymentMethodsDeleteMutation,
    prefetchFintechLayout,useRateOnUpgradePlan}