import React from "react";
import {useFintechLayouts} from '../services/AsaApiServices'
interface ConfirmDialogProps {
  isOpen: boolean;
  title: string;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  title,
  message,
  onCancel,
  onConfirm,
}) => {
  
  const layout=useFintechLayouts()
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mx-4">
        {/* Adjusted width and margin classes */}
        <h2 className="text-sm font-bold mb-4 text-[#4E4E4E] font-poppins">
          {title}
        </h2>
        <p className="text-md mb-4 text-[#4E4E4E] font-poppins">{message}</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onCancel}
            className="py-2 px-4 bg-gray-500 text-white rounded-lg font-poppins"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="py-2 px-4 bg-red-500 text-white rounded-lg font-poppins"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
